import React, { useState, useMemo, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '../../../api';
import { useInsurance } from '../../../context/Insurance';
import shade from '../../../utils/shade';
import CardLoader from '../../CardLoader';
import NotificationListing from './NotificationListing';
import useConfigStore from '../../../stores/configStore';
import useCustomerStore from '../../../stores/customerStore';
import useSaasPlatformStore from '../../../stores/saasPlatformStore';
import useProductStore from '../../../stores/productStore';
import useErrorStore from '../../../stores/errorStore';
import useErrorHandledMutation from '../../../hooks/useErrorHandledMutation';
import useLatestQuotesWithPolicies from '../../../hooks/useLatestQuotesWithPolicies';
import useToken from '../../../hooks/useToken';
import ErrorIcon from '../../icons/ErrorIcon';
import useWidgetStore, { CurrentTabEnum } from '../../../stores/widgetStore';
import QuoteLoading from '../Policy/QuoteLoading';
import ErrorScreen from '../Policy/ErrorScreen';

export default function Notifications({ show }) {
  const [selectedQuote, setSelectedQuote] = useState(null);

  const { setCurrentTab, setModalOpen } = useWidgetStore();
  const { keys, style } = useConfigStore();
  const { primaryColor: color, logo } = style;
  const { widgetType } = keys;
  const {
    extCustomerName: customerName,
    extCustomerID: externalID,
    customerId: customerID,
    setExternalCustomer,
    ...extCustomer
  } = useCustomerStore();
  const { error, setError } = useErrorStore();
  const latestQuotesWithPolicies = useLatestQuotesWithPolicies();
  const queryClient = useQueryClient();
  const customer = queryClient.getQueryData(['customer']);

  const { latestQuote, latestPolicy } = useMemo(() => {
    const latestQuote =
      latestQuotesWithPolicies.find((q) => q.id === selectedQuote) || null;
    const latestPolicy = latestQuote?.policy || null;

    return { latestQuote, latestPolicy };
  }, [latestQuotesWithPolicies, selectedQuote]);

  const getPurchasedQuote = () => {
    let latestQuoteIndex = customer?.data?.data?.Quotes.findIndex(
      (q) => q?.quoteId === latestPolicy?.quoteId,
    );
    if (latestQuoteIndex !== -1)
      return customer?.data?.data?.Quotes[latestQuoteIndex];
  };

  const purchasedQuote = getPurchasedQuote();

  useEffect(() => {
    if (latestQuotesWithPolicies.length === 1) {
      setSelectedQuote(latestQuotesWithPolicies[0].id);
    }
  }, [latestQuotesWithPolicies]);

  if (queryClient.getQueryState(['customer'])?.status === 'loading')
    return <QuoteLoading />;

  if (error) return <ErrorScreen />;

  // if (
  //   latestQuotesWithPolicies.length > 0 &&
  //   typeof selectedQuote !== 'string'
  // ) {
  //   return <NotificationListing onSelectPolicy={setSelectedQuote} />;
  // }

  return (
    <div className="">
      {show && <NotificationListing onSelectPolicy={setSelectedQuote} />}
      {/* {show &&
        latestQuote &&
        latestQuote.status !== 'pending' &&
        latestQuote.status !== 'rejected' &&
        ((latestPolicy && purchasedQuote?.quoteId !== latestQuote?.quoteId) ||
          customer?.data?.data?.Quotes?.length > 0) && (
          <div
            className={`rounded-3xl mx-2 mt-30 px-12 bg-backgroundColor my-2 flex flex-col h-full justify-center text-textColor`}
          >
            <div className="flex flex-col items-center justify-center">
              <ErrorIcon />
              {latestPolicy &&
              purchasedQuote?.id !== latestQuote?.id ? (
                <>
                  <span className="text-xl text-center w-full mt-5 text-[#202851]">
                    Policy Number:
                  </span>
                  <span className="text-2xl font-bold text-center w-full mt-2 text-[#1F2751]">
                    {latestPolicy?.id}
                  </span>
                </>
              ) : (
                <span className="text-xl text-center w-full mt-5 text-[#202851]">
                  Policy Risk Management
                </span>
              )}
            </div>

            <div className="mt-5 rounded-md bg-[#FDECEB] p-1">
              <span className="text-[#EA4335] text-sm mt-0.5">
                {latestPolicy && purchasedQuote?.id !== latestQuote?.id
                  ? ' It looks like your business has changed, do you want to review your insurance policy?'
                  : 'It looks like your business has changed, see what has changed to review your insurance policy'}
              </span>
            </div>

            <div className="flex items-stretch gap-x-4 mt-5">
              <button
                className={`w-full h-full rounded-lg border-2 border-primary m-auto text-primary font-bold tracking-lighter hover:bg-primary hover:text-white`}
              >
                <a
                  className="block px-4 py-2"
                  // onClick={(e) => {
                  //   updateTab(e, 5);
                  //   setModalOpen(true);
                  // }}
                >
                  Contact Broker
                </a>
              </button>
              <button
                className={`w-full h-full rounded-lg border-2 border-primary m-auto text-primary font-bold tracking-lighter hover:bg-primary hover:text-white`}
              >
                <a
                  className="block px-4 py-2"
                  onClick={(e) => {
                    setSelectedQuote({
                      policyId: latestPolicy?.id,
                      externalId: purchasedQuote?.externalId,
                    });
                    setCurrentTab(CurrentTabEnum.POLICY_CHANGES);
                    setModalOpen(true);
                  }}
                >
                  Review Changes
                </a>
              </button>
            </div>
          </div>
        )} */}

      {!show && (
        <div
          style={{
            height: '100%',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            letterSpacing: 1,
            color,
          }}
        >
          No new notifications
        </div>
      )}
    </div>
  );
}
