import React from 'react';
import BreadCrumbs from '../../common/BreadCrumbs';
import useWidgetStore, { CurrentTabEnum } from '../../../stores/widgetStore';
import useConfigStore from '../../../stores/configStore';
import useLatestQuote from '../../../hooks/useLatestQuote';
import QuoteLoading from '../Policy/QuoteLoading';
import { useQueryClient } from '@tanstack/react-query';
var getQuoteInfo = function (quoteDetails, updateTab) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
    switch (quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerIdentifier) {
        case 'tigerLabs':
            return [
                {
                    name: 'Quote Number',
                    value: (_b = (_a = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _a === void 0 ? void 0 : _a.rateQuote) === null || _b === void 0 ? void 0 : _b.number
                },
                {
                    name: 'Agent Name:',
                    value: (_d = (_c = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _c === void 0 ? void 0 : _c.rateQuote) === null || _d === void 0 ? void 0 : _d.agentCompany
                },
                {
                    name: 'yearly premium:',
                    value: "\u20AC ".concat((_g = ((quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total) -
                        Number((_f = (_e = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _e === void 0 ? void 0 : _e.rateQuote) === null || _f === void 0 ? void 0 : _f.iptAmount))) === null || _g === void 0 ? void 0 : _g.toFixed(2))
                },
                {
                    name: 'IPT:',
                    value: "\u20AC ".concat((_j = (_h = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _h === void 0 ? void 0 : _h.rateQuote) === null || _j === void 0 ? void 0 : _j.iptAmount)
                },
                {
                    name: 'Total Payable Amount:',
                    value: "\u20AC ".concat((_k = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total) === null || _k === void 0 ? void 0 : _k.toFixed(2))
                },
                {
                    name: 'coverages',
                    value: (React.createElement("a", { className: "underline decoration-white text-textColor decoration-2 cursor-pointer", onClick: function (e) { return updateTab(e, CurrentTabEnum.COVERAGES); } }, "Check coverages here"))
                },
            ];
        case 'opal':
            return [
                {
                    name: 'Quote Number',
                    value: "".concat((_m = (_l = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _l === void 0 ? void 0 : _l.identifier) === null || _m === void 0 ? void 0 : _m.split('-')[0], "-").concat((_p = (_o = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _o === void 0 ? void 0 : _o.identifier) === null || _p === void 0 ? void 0 : _p.split('-')[0])
                },
                {
                    name: 'yearly premium:',
                    value: "\u20AC ".concat((_u = (((_r = (_q = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _q === void 0 ? void 0 : _q.premium) === null || _r === void 0 ? void 0 : _r.gross) -
                        0.2 * ((_t = (_s = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _s === void 0 ? void 0 : _s.premium) === null || _t === void 0 ? void 0 : _t.gross))) === null || _u === void 0 ? void 0 : _u.toFixed(2))
                },
                {
                    name: 'IPT:',
                    value: "\u20AC ".concat((_x = ((((_w = (_v = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _v === void 0 ? void 0 : _v.premium) === null || _w === void 0 ? void 0 : _w.gross) / 100) *
                        20)) === null || _x === void 0 ? void 0 : _x.toFixed(2))
                },
                {
                    name: 'Total Payable Amount:',
                    value: "\u20AC ".concat((_0 = (_z = (_y = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _y === void 0 ? void 0 : _y.premium) === null || _z === void 0 ? void 0 : _z.gross) === null || _0 === void 0 ? void 0 : _0.toFixed(2))
                },
                {
                    name: 'coverages',
                    value: (React.createElement("a", { className: "underline decoration-white text-textColor decoration-2 cursor-pointer", onClick: function (e) { return updateTab(e, CurrentTabEnum.COVERAGES); } }, "Check coverages here"))
                },
            ];
        default:
            return [
                {
                    name: 'Quote Number',
                    value: quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.quoteId
                },
                {
                    name: 'yearly premium:',
                    value: "\u20AC ".concat((_1 = (Number(quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total) -
                        0.2 * Number(quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total))) === null || _1 === void 0 ? void 0 : _1.toFixed(2))
                },
                {
                    name: 'IPT:',
                    value: "\u20AC ".concat((_2 = ((Number(quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total) / 100) * 20)) === null || _2 === void 0 ? void 0 : _2.toFixed(2))
                },
                {
                    name: 'Total Payable Amount:',
                    value: "\u20AC ".concat((_3 = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total) === null || _3 === void 0 ? void 0 : _3.toFixed(2))
                },
                {
                    name: 'coverages',
                    value: (React.createElement("a", { className: "underline decoration-white text-textColor decoration-2 cursor-pointer", onClick: function (e) { return updateTab(e, CurrentTabEnum.COVERAGES); } }, "Check coverages here"))
                },
            ];
    }
};
var QuoteInfo = function (_a) {
    var _b, _c, _d, _e;
    var _f = _a.hideDesc, hideDesc = _f === void 0 ? false : _f, _g = _a.fullWidth, fullWidth = _g === void 0 ? false : _g;
    var queryClient = useQueryClient();
    var customer = queryClient.getQueryData(['customer']);
    var latestQuote = useLatestQuote();
    var _h = useWidgetStore(), setCurrentTab = _h.setCurrentTab, setModalOpen = _h.setModalOpen;
    var keys = useConfigStore().keys;
    var updateTab = function (e, number) {
        e.preventDefault();
        setCurrentTab(number);
    };
    if (!latestQuote && ((_d = (_c = (_b = customer === null || customer === void 0 ? void 0 : customer.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.Quotes) === null || _d === void 0 ? void 0 : _d.length) !== 0) {
        return React.createElement(QuoteLoading, null);
    }
    // if (!latestQuote?.partnerData?.rateQuote) {
    //   return <h1>No Information On this page</h1>;
    // }
    return (React.createElement("div", { className: "px-4 flex flex-col items-center justify-center" },
        !hideDesc && (React.createElement(React.Fragment, null,
            React.createElement(BreadCrumbs, { links: [
                    {
                        name: 'Policy',
                        link: 1,
                        closeModal: (keys === null || keys === void 0 ? void 0 : keys.widgetType) == 'broker' && true
                    },
                    {
                        name: 'Quote Information',
                        link: 4,
                        active: true
                    },
                ] }),
            React.createElement("h1", { className: "font-bold text-xl mb-1 text-textColor" }, "Quote Information"),
            React.createElement("p", { className: "mb-6 text-textColor font-extralight text-xs text-balance w-8/12 antialiased" }, "This section provides crucial details about your insurance quote. It offers a quick overview of coverage types, with more specific information to follow."))),
        React.createElement("div", { className: "flex flex-col items-center justify-center gap-y-3 w-full" },
            React.createElement("div", { className: "flex flex-col ".concat(fullWidth ? 'w-10/12' : 'w-8/12', " font-bold capitalize") }, (_e = getQuoteInfo(latestQuote, updateTab)) === null || _e === void 0 ? void 0 : _e.map(function (tableRow) {
                return (React.createElement("div", { className: "flex" },
                    React.createElement("div", { className: "flex-1 p-2 text-left ml-2 text-primary" }, tableRow.name),
                    React.createElement("div", { className: "flex-1 p-2 text-left ml-2 text-textColor" }, tableRow.value)));
            })),
            !hideDesc && (React.createElement("button", { className: "rounded-2xl border-2 border-primary text-primary font-bold mt-4 tracking-lighter px-8 py-3 hover:bg-primary hover:text-white", type: "button", onClick: function () {
                    if ((keys === null || keys === void 0 ? void 0 : keys.widgetType) == 'broker') {
                        setModalOpen(false);
                    }
                    setCurrentTab(CurrentTabEnum.POLICY);
                } }, "Back")))));
};
export default QuoteInfo;
