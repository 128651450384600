var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import StepCompletedIcon from '../icons/StepCompletedIcon';
import StepInProgressIcon from '../icons/StepInProgressIcon';
import CloseIcon from '../icons/CloseIcon';
import useWidgetStore, { CurrentTabEnum } from '../../stores/widgetStore';
import useLatestPolicy from '../../hooks/useLatestPolicy';
var Steps = function (_a) {
    var stepText = _a.stepText, isStepActive = _a.isStepActive, isStepCompleted = _a.isStepCompleted, stepIndex = _a.stepIndex;
    return (React.createElement("div", { className: "flex flex-col items-center mr-2 gap-y-1 md:w-full md:min-w-max sm:flex-row sm:gap-x-1" },
        isStepCompleted ? (React.createElement(StepCompletedIcon, null)) : (React.createElement(StepInProgressIcon, { active: isStepActive })),
        React.createElement("span", { className: "text-sm font-semibold ".concat(isStepActive
                ? 'text-primary'
                : isStepCompleted
                    ? 'text-[#1F2751]'
                    : 'text-[#E9EDF7]', " sm:text-lg") }, stepText)));
};
var PolicyStepsHeader = function (_a) {
    var pageName = _a.pageName, steps = _a.steps, nextButtonText = _a.nextButtonText, backButtonText = _a.backButtonText, handleNext = _a.handleNext, handleBack = _a.handleBack, _b = _a.hideNextButton, hideNextButton = _b === void 0 ? false : _b, _c = _a.isBackDisabled, isBackDisabled = _c === void 0 ? false : _c, _d = _a.isNextDisabled, isNextDisabled = _d === void 0 ? false : _d, handleClose = _a.handleClose;
    var latestPolicy = useLatestPolicy();
    var _e = useWidgetStore(), toggleModal = _e.toggleModal, setCurrentTab = _e.setCurrentTab, setFormActive = _e.setFormActive;
    var handleFormClose = function () {
        var isConfirmed = window.confirm('Are you sure you want to close the form?');
        if (isConfirmed) {
            if (handleClose) {
                handleClose();
            }
            else {
                if (latestPolicy)
                    setCurrentTab(CurrentTabEnum.POLICY);
                toggleModal();
            }
        }
    };
    return (React.createElement("div", { className: "flex flex-col items-center w-full p-4 border-b-2 border-[#F4F7FE] gap-y-3" },
        React.createElement("div", { className: "w-full flex items-center justify-between lg:justify-center" },
            React.createElement("div", { className: "flex items-center gap-x-2 lg:absolute left-4" },
                handleBack && (React.createElement("button", { type: "button", onClick: function () { return handleFormClose(); }, className: "rounded-full p-2 hover:bg-gray-100 focus:bg-gray-200" },
                    React.createElement(CloseIcon, null))),
                React.createElement("button", { className: "px-6 py-1.5 text-lg text-[#707EAE] font-medium border-2 border-[#C8CEE3] rounded-lg disabled:opacity-40", type: "button", onClick: function () { return (handleBack ? handleBack() : handleFormClose()); }, disabled: isBackDisabled }, backButtonText ? backButtonText : 'Close')),
            pageName && (React.createElement("span", { className: "text-2xl font-bold text-[#1F2751] text-center mt-2" }, pageName)),
            !pageName && (React.createElement("div", { className: "items-center w-full max-w-[70%] hidden sm:flex " }, (steps === null || steps === void 0 ? void 0 : steps.length) > 0 &&
                (steps === null || steps === void 0 ? void 0 : steps.map(function (step, index) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Steps, __assign({ stepIndex: index }, step)),
                        (steps === null || steps === void 0 ? void 0 : steps.length) - 1 !== index && (React.createElement("div", { className: "w-full h-[1px] ".concat(step.isStepCompleted ? 'bg-primary' : 'bg-[#E9EDF7]') }))));
                })))),
            !hideNextButton && (React.createElement("div", { className: "lg:absolute right-4" },
                React.createElement("button", { className: "px-4 py-1.5 text-lg text-white bg-[#FD515E] font-medium border-2 border-[#FD515E] rounded-lg", onClick: function () { return handleNext && handleNext(); }, type: "submit", disabled: isNextDisabled }, nextButtonText ? nextButtonText : 'Continue')))),
        React.createElement("div", { className: "w-full ".concat(hideNextButton && 'md:mt-10', " sm:hidden") }, !pageName && (React.createElement("div", { className: "flex items-start w-full" }, (steps === null || steps === void 0 ? void 0 : steps.length) > 0 &&
            (steps === null || steps === void 0 ? void 0 : steps.map(function (step, index) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Steps, __assign({ stepIndex: index }, step)),
                    (steps === null || steps === void 0 ? void 0 : steps.length) - 1 !== index && (React.createElement("div", { className: "md:w-full h-[1px] mt-4 ".concat(step.isStepActive ? 'bg-primary' : 'bg-[#E9EDF7]') }))));
            })))))));
};
export default PolicyStepsHeader;
