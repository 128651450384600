import React from 'react';
var QuoteRejectedIcon = function () {
    return (React.createElement("svg", { width: 72, height: 72, viewBox: "0 0 72 72", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("rect", { opacity: "0.1", width: 72, height: 72, rx: "20.5714", fill: "#6768EE" }),
        React.createElement("path", { d: "M36.0001 40.584H27.6667C26.9834 40.584 26.4167 40.0173 26.4167 39.334C26.4167 38.6507 26.9834 38.084 27.6667 38.084H36.0001C36.6834 38.084 37.2501 38.6507 37.2501 39.334C37.2501 40.0173 36.6834 40.584 36.0001 40.584Z", className: "fill-primary" }),
        React.createElement("path", { d: "M27.6662 27.1833C27.3496 27.1833 27.0329 27.0667 26.7829 26.8167L20.5329 20.5667C20.0496 20.0833 20.0496 19.2833 20.5329 18.8C21.0162 18.3167 21.8162 18.3167 22.2996 18.8L28.5496 25.05C29.0329 25.5333 29.0329 26.3333 28.5496 26.8167C28.2996 27.05 27.9829 27.1833 27.6662 27.1833Z", className: "fill-primary" }),
        React.createElement("path", { d: "M21.3503 27.2497C21.0337 27.2497 20.717 27.1331 20.467 26.8831C19.9837 26.3997 19.9837 25.5997 20.467 25.1164L26.717 18.8664C27.2003 18.3831 28.0003 18.3831 28.4837 18.8664C28.967 19.3497 28.967 20.1497 28.4837 20.6331L22.2337 26.8831C22.0003 27.1331 21.667 27.2497 21.3503 27.2497Z", className: "fill-primary" }),
        React.createElement("path", { d: "M41.0001 33.916H27.6667C26.9834 33.916 26.4167 33.3493 26.4167 32.666C26.4167 31.9827 26.9834 31.416 27.6667 31.416H41.0001C41.6834 31.416 42.2501 31.9827 42.2501 32.666C42.2501 33.3493 41.6834 33.916 41.0001 33.916Z", className: "fill-primary" }),
        React.createElement("path", { d: "M51.0001 43.9173C50.3167 43.9173 49.7501 43.3507 49.7501 42.6673V29.3173C49.7501 22.2673 47.3501 20.834 42.6001 20.584H32.6667C31.9834 20.584 31.4167 20.0173 31.4167 19.334C31.4167 18.6507 31.9834 18.084 32.6667 18.084H42.6667C49.5001 18.4507 52.2501 21.7007 52.2501 29.3173V42.6673C52.2501 43.3507 51.6834 43.9173 51.0001 43.9173Z", className: "fill-primary" }),
        React.createElement("path", { d: "M41 53.9156H31C21.6333 53.9156 19.75 49.599 19.75 42.6323V31.0156C19.75 30.3323 20.3167 29.7656 21 29.7656C21.6833 29.7656 22.25 30.3323 22.25 31.0156V42.6323C22.25 48.8323 23.4667 51.4156 31 51.4156H41C41.6833 51.4156 42.25 51.9823 42.25 52.6656C42.25 53.349 41.6833 53.9156 41 53.9156Z", className: "fill-primary" }),
        React.createElement("path", { d: "M41 53.916C40.8333 53.916 40.6833 53.8827 40.5167 53.816C40.05 53.616 39.75 53.166 39.75 52.666V47.666C39.75 43.6327 41.9667 41.416 46 41.416H51C51.5 41.416 51.9667 41.716 52.15 42.1827C52.3333 42.6493 52.2333 43.1827 51.8833 43.5494L41.8833 53.5493C41.65 53.7827 41.3167 53.916 41 53.916ZM46 43.916C43.3667 43.916 42.25 45.0327 42.25 47.666V49.6493L47.9833 43.916H46Z", className: "fill-primary" })));
};
export default QuoteRejectedIcon;
