var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from "zustand";
var useSaasPlatformStore = create(function (set) { return ({
    id: null,
    saasName: null,
    brokerFormLink: null,
    policyPageHeading: null,
    policyPageText: null,
    niche: null,
    email: null,
    phone: null,
    website: null,
    widgetStatus: null,
    totalClients: null,
    createdAt: null,
    updatedAt: null,
    RoleRoleId: null,
    customerRoleId: null,
    platformRoleId: null,
    Products: null,
    setSaasPlatformStore: function (config) {
        set(function (state) { return (__assign(__assign({}, state), config)); });
    }
}); });
export default useSaasPlatformStore;
