import React from 'react';
var createArrayFromNumber = function (number) {
    return Array.from(Array(number).keys());
};
var ProgressBar = function (_a) {
    var active = _a.active;
    return (React.createElement("div", { className: "w-full bg-primary h-1.5 rounded-full ".concat(active ? 'opacity-100' : 'opacity-10') }));
};
var PolicyFormProgressBar = function (_a) {
    var _b;
    var steps = _a.steps, activeStep = _a.activeStep, _c = _a.type, type = _c === void 0 ? 'multi-line' : _c;
    return (React.createElement("div", { className: "w-full flex flex-col items-center gap-y-3" },
        React.createElement("div", { className: "flex items-baseline text-xl  gap-x-1" },
            React.createElement("span", { className: "font-bold" }, activeStep),
            React.createElement("span", { className: "font-semibold" },
                "/ ",
                steps)),
        React.createElement("div", { className: "flex items-center justify-center gap-x-1 w-full" },
            type === 'single-line' && (React.createElement("div", { className: "relative h-1.5 w-full rounded-full overflow-hidden" },
                React.createElement("div", { className: "absolute top-0 left-0 right-0 bottom-0 bg-primary opacity-10" }),
                React.createElement("div", { className: "h-full rounded-full bg-primary", style: {
                        width: "".concat((activeStep / steps) * 100, "%")
                    } }))),
            type === 'multi-line' &&
                ((_b = createArrayFromNumber(steps)) === null || _b === void 0 ? void 0 : _b.map(function (step, index) {
                    return React.createElement(ProgressBar, { active: activeStep >= index + 1 });
                })))));
};
export default PolicyFormProgressBar;
