import React from 'react';
// @ts-ignore
import arrowBack from '../../../assets/arrowBack.png';
var ClaimItem = function (_a) {
    var claim = _a.claim, setActiveClaim = _a.setActiveClaim;
    var getClaimTotal = function (formatted) {
        var _a;
        var sum = 0;
        (_a = claim.item) === null || _a === void 0 ? void 0 : _a.map(function (ci) {
            var itemAmount = Number(ci === null || ci === void 0 ? void 0 : ci.damageAmount) > 0 ? Number(ci === null || ci === void 0 ? void 0 : ci.damageAmount) : 0;
            sum += itemAmount;
        });
        return formatted ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(sum) : sum;
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "relative ripple cursor-pointer rounded-2xl ml-2 mr-2 px-8 pb-8 pt-4 bg-[#f5f5f5] flex flex-row align-center justify-between my-4", onClick: function () { return setActiveClaim(claim); } },
            React.createElement("div", { className: "mt-3 flex flex-col align-start justify-start text-left\t" },
                React.createElement("span", { className: "text-base font-bold" },
                    "Claim No. ",
                    React.createElement("span", { className: "text-blue-500" }, claim === null || claim === void 0 ? void 0 : claim.claimId)),
                React.createElement("span", { className: "text-primary text-gray-400 font-bold" }, claim === null || claim === void 0 ? void 0 : claim.title),
                React.createElement("span", { className: "text-primary text-sm text-gray-400 font-bold" },
                    "Total Claimed: ",
                    React.createElement("span", { className: "font-normal" }, getClaimTotal(true)))),
            React.createElement("div", { className: "absolute m-0", style: {
                    right: '20px',
                    top: '42px'
                } },
                React.createElement("img", { src: arrowBack, alt: "success" })))));
};
export default ClaimItem;
