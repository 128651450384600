import React from 'react';
import VerifiedIcon from '../../../icons/VerifiedIcon';
import useWidgetStore from '../../../../stores/widgetStore';
import PendingIcon from '../../../icons/PendingIcon';
import useLatestQuote from '../../../../hooks/useLatestQuote';
var PolicyPending = function () {
    var _a = useWidgetStore(), modalOpen = _a.modalOpen, setModalOpen = _a.setModalOpen;
    var latestQuote = useLatestQuote();
    return (React.createElement("div", null, modalOpen ? (React.createElement("div", { className: "flex flex-col items-center justify-start sm:px-4" },
        React.createElement("div", { className: "flex flex-col items-center justify-center" },
            React.createElement(PendingIcon, null),
            React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" }, "Your policy will be issued in a few days."),
            React.createElement("span", { className: "text-[16px] mt-3" }, "We are still reviewing some legal issues that may take us a few days to resolve"),
            React.createElement("button", { className: "px-3 py-1.5 text-sm text-white bg-[#FD515E] font-bold border-2 border-[#FD515E] rounded-lg mt-6", onClick: function () { return setModalOpen(false); } }, "Understood")))) : (React.createElement("div", { className: "rounded-3xl mx-2 mt-30 px-12 bg-backgroundColor my-2 flex flex-col h-full justify-center text-textColor" },
        React.createElement("div", { className: "flex flex-col items-center justify-center" },
            React.createElement(VerifiedIcon, null),
            React.createElement("span", { className: "text-xl font-bold text-center w-full mt-5 text-[#1F2751]" }, "Quote Number:"),
            React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" }, latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.id),
            React.createElement("div", { className: "flex items-center mt-3 gap-x-4 text-[#202851]" },
                React.createElement("span", { className: "w-2 h-2 bg-[#F3A449] rounded-full" }),
                React.createElement("span", null, "In Progress"))),
        React.createElement("button", { className: "rounded-2xl border-2 border-primary text-primary text-[16px] font-bold mt-4 mx-auto tracking-lighter px-8 py-4", onClick: function () {
                //   setModalOpen(true);
                //   setPurchaseConfirmation(true);
            } }, "Coverages")))));
};
export default PolicyPending;
