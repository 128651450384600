var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import PolicyStepsHeader from '../../../common/PolicyStepsHeader';
import usePolicyChangesStore from '../../../../stores/policyChangesStore';
import useWidgetStore, { CurrentTabEnum } from '../../../../stores/widgetStore';
import { format } from 'date-fns';
import * as changeCase from 'change-case';
import AccorddionCloseIcon from '../../../icons/AccordionCloseIcon';
import AccordionOpenIcon from '../../../icons/AccordionOpenIcon';
import { flattenObject } from '../../../../utils/common';
import _ from 'lodash';
var ViewChanges = function () {
    var _a, _b;
    var _c = useState([]), openAccordions = _c[0], setOpenAccordions = _c[1];
    var _d = usePolicyChangesStore(), originalQuoteData = _d.originalQuoteData, prevQuoteData = _d.prevQuoteData, currentQuoteData = _d.currentQuoteData;
    var _e = useWidgetStore(), setCurrentTab = _e.setCurrentTab, setModalOpen = _e.setModalOpen;
    useEffect(function () {
        var _a;
        if (!(openAccordions.length > 0)) {
            setOpenAccordions(__spreadArray(__spreadArray([], openAccordions, true), [
                _.sortBy(Object.keys((_a = currentQuoteData === null || currentQuoteData === void 0 ? void 0 : currentQuoteData.items) === null || _a === void 0 ? void 0 : _a.requiredParams))[0],
            ], false));
        }
    }, [currentQuoteData]);
    var toggleAccordion = function (objKey) {
        setOpenAccordions(function (prev) {
            return prev.includes(objKey)
                ? prev.filter(function (key) { return key !== objKey; })
                : __spreadArray(__spreadArray([], prev, true), [objKey], false);
        });
    };
    return (React.createElement("div", { className: "w-full" },
        React.createElement(PolicyStepsHeader, { pageName: "Full Change Summary", handleClose: function () {
                setCurrentTab(CurrentTabEnum.POLICY);
                setModalOpen(false);
            }, hideNextButton: true }),
        React.createElement("div", { className: "w-full flex items-start justify-center" },
            React.createElement("div", { className: "flex flex-col items-center gap-y-6 p-6 w-full" }, (_b = _.sortBy(Object.keys((_a = currentQuoteData === null || currentQuoteData === void 0 ? void 0 : currentQuoteData.items) === null || _a === void 0 ? void 0 : _a.requiredParams))) === null || _b === void 0 ? void 0 : _b.map(function (objKey) {
                var _a, _b;
                return (React.createElement("div", { className: "w-full" },
                    React.createElement("button", { onClick: function () { return toggleAccordion(objKey); }, className: "flex items-center justify-start gap-x-4 w-full bg-[#F8FAFE] px-4 py-3 rounded-lg mb-4 capitalize font-semibold text-lg" },
                        openAccordions.includes(objKey) ? (React.createElement(AccorddionCloseIcon, null)) : (React.createElement(AccordionOpenIcon, null)),
                        objKey),
                    openAccordions.includes(objKey) && (React.createElement("table", { className: "w-full border-collapse border border-gray-300 rounded-lg" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { className: "border border-[#F0F2F9] p-4 w-[25%]" }),
                                originalQuoteData && (React.createElement("th", { className: "border border-[#F0F2F9] font-normal text-lg text-[#1F2751] p-4 w-[25%]" }, "1st Quote / Policy")),
                                prevQuoteData && (React.createElement("th", { className: "border border-[#F0F2F9] font-normal text-lg text-[#1F2751] p-4 w-[25%]" }, format(new Date(prevQuoteData === null || prevQuoteData === void 0 ? void 0 : prevQuoteData.createdAt), 'dd MMMM yyyy'))),
                                currentQuoteData && (React.createElement("th", { className: "border border-[#F0F2F9] font-semibold text-lg text-[#1F2751] p-4 w-[25%]" }, format(new Date(currentQuoteData === null || currentQuoteData === void 0 ? void 0 : currentQuoteData.createdAt), 'dd MMMM yyyy'))))),
                        React.createElement("tbody", null, (_b = Object.keys(flattenObject((_a = currentQuoteData === null || currentQuoteData === void 0 ? void 0 : currentQuoteData.items) === null || _a === void 0 ? void 0 : _a.requiredParams[objKey]))) === null || _b === void 0 ? void 0 : _b.map(function (key) {
                            var _a, _b, _c, _d, _e, _f;
                            var originalDataFlatten = flattenObject((_a = originalQuoteData.items) === null || _a === void 0 ? void 0 : _a.requiredParams[objKey]);
                            var originalValue = originalDataFlatten[key]
                                ? originalDataFlatten[key]
                                : null;
                            var lastDataFlatten = flattenObject((_b = prevQuoteData === null || prevQuoteData === void 0 ? void 0 : prevQuoteData.items) === null || _b === void 0 ? void 0 : _b.requiredParams[objKey]);
                            var lastValue = lastDataFlatten[key]
                                ? lastDataFlatten[key]
                                : null;
                            var currentDataFlatten = flattenObject((_c = currentQuoteData === null || currentQuoteData === void 0 ? void 0 : currentQuoteData.items) === null || _c === void 0 ? void 0 : _c.requiredParams[objKey]);
                            var lastestValue = currentDataFlatten[key];
                            var keyMod = (_f = (_e = (_d = key
                                .split) === null || _d === void 0 ? void 0 : _d.call(key, '.')) === null || _e === void 0 ? void 0 : _e.map) === null || _f === void 0 ? void 0 : _f.call(_e, function (itm) { return changeCase.capitalCase(itm); }).join(' • ');
                            return (React.createElement("tr", null,
                                React.createElement("td", { className: "border border-[#F0F2F9] p-4 text-sm font-semibold text-[#1F2751] w-[25%] text-left capitalize" }, keyMod),
                                originalValue && (React.createElement("td", { className: "border border-[#F0F2F9] p-4 text-lg font-semibold text-[#1F2751] w-[25%] text-center" }, originalValue)),
                                lastValue && (React.createElement("td", { className: "border border-[#F0F2F9] p-4 text-lg font-semibold text-[#1F2751] w-[25%] text-center" }, originalValue == lastValue ? '-' : lastValue)),
                                React.createElement("td", { className: "border border-[#F0F2F9] p-4 text-lg font-semibold text-primary w-[25%] text-center" }, lastValue == lastestValue ||
                                    (!lastValue && lastestValue == originalValue)
                                    ? '-'
                                    : lastestValue)));
                        }))))));
            })))));
};
export default ViewChanges;
