import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
//
import useLatestQuotesWithPolicies from '../../../hooks/useLatestQuotesWithPolicies';
import useCustomerStore from '../../../stores/customerStore';
import useConfigStore from '../../../stores/configStore';
// import useConfigStore from '../../../stores/configStore';
var isApplicationDataAvailable = function (quotes, applicationFormData) {
    var quoteExternalRefs = quotes.map(function (quote) { return quote.externalId; });
    var applicationDataRefs = Object.keys(applicationFormData);
    var filterUnsuedApplicationData = applicationDataRefs.filter(function (v) { return !quoteExternalRefs.includes(v); });
    return (filterUnsuedApplicationData === null || filterUnsuedApplicationData === void 0 ? void 0 : filterUnsuedApplicationData.length) > 0;
};
var PolicyListing = function (_a) {
    var onSelectPolicy = _a.onSelectPolicy, onNewPolicy = _a.onNewPolicy;
    var applicationFormData = useCustomerStore().applicationFormData;
    var latestQuotesWithPolicies = useLatestQuotesWithPolicies();
    var primaryColor = useConfigStore().style.primaryColor;
    var quoteStatusColorMapper = {
        pending: 'bg-[#F3A449]',
        complete: 'bg-primary',
        bound: 'bg-[#42D58E]',
        rejected: 'bg-[#FD515E]',
        'MT Draft': 'bg-[#42D58E]'
    };
    return (React.createElement("div", { className: "flex flex-col h-full" },
        React.createElement("ul", { className: "flex-1" }, latestQuotesWithPolicies.map(function (quote) {
            var _a, _b;
            var idParts = ((_a = quote === null || quote === void 0 ? void 0 : quote.policy) === null || _a === void 0 ? void 0 : _a.id)
                ? quote.policy.id.split('-')
                : quote.id.split('-');
            var idDisplay = "".concat(idParts[0], "-").concat(idParts[1]);
            var displayDate = '';
            var productName = (_b = quote.Product) === null || _b === void 0 ? void 0 : _b.productName;
            if (!['complete', 'pending', 'rejected'].includes(quote.status)) {
                var quoteDate = new Date(quote.createdAt);
                quoteDate.setFullYear(quoteDate.getFullYear() + 1);
                displayDate = "".concat(quoteDate.getDate(), "/").concat(quoteDate.getMonth() + 1, "/").concat(quoteDate.getFullYear());
            }
            else if (quote.status === 'rejected') {
                displayDate = 'Rejected';
            }
            else if (quote.status == 'complete') {
                displayDate = 'Ready for Purchase';
            }
            else {
                displayDate = 'In Progress';
            }
            return (React.createElement("li", { key: quote.id, className: "[&:not(:last-child)]:mb-5" },
                React.createElement("button", { className: "flex min-h-[84px] items-center w-full px-6", onClick: function () {
                        onSelectPolicy(quote);
                    } },
                    React.createElement("div", { className: "flex-1 text-left" },
                        React.createElement("h4", { className: "text-[#202851] text-xs font-semibold" }, "Policy Number:"),
                        React.createElement("h3", { className: "font-bold text-lg leading-[26px]" }, idDisplay),
                        productName && (React.createElement("h4", { className: "mt-1 text-sm leading-[17px]" }, productName))),
                    React.createElement("div", { className: "flex items-center gap-2" },
                        React.createElement("div", { className: "h-2 w-2 ".concat(quoteStatusColorMapper[quote.status], " rounded-full") }),
                        React.createElement("span", null, displayDate),
                        React.createElement(FontAwesomeIcon, { icon: faChevronRight, size: "xs", color: primaryColor })))));
        })),
        isApplicationDataAvailable(latestQuotesWithPolicies, applicationFormData) && (React.createElement("div", { className: "pt-4 px-6" },
            React.createElement("button", { type: "button", className: "w-full border border-primary h-12 rounded-lg mx-auto", onClick: onNewPolicy },
                React.createElement("span", { className: "font-bold text-primary" }, "New Policy"))))));
};
export default PolicyListing;
