import React from 'react';
import useErrorStore from '../../../stores/errorStore';
import ErrorIcon from '../../icons/ErrorIcon';
var ErrorScreen = function () {
    var error = useErrorStore().error;
    return (React.createElement("div", { className: "rounded-3xl mx-2 mt-30 px-12 pb-8 pt-4 bg-backgroundColor my-2 flex flex-col h-full justify-center" },
        React.createElement("div", { className: "flex flex-col gap-y-6 items-center justify-center" },
            React.createElement(ErrorIcon, null),
            React.createElement("span", { className: "text-2xl font-bold text-center w-full text-[#1F2751] mb-4" }, error))));
};
export default ErrorScreen;
