import React from 'react';
import shade from '../../../../utils/shade';
import ClaimItem from '../Claim';
export default function ClaimList(_a) {
    var _b, _c, _d;
    var primaryColor = _a.primaryColor, allClaimsData = _a.allClaimsData, setPage = _a.setPage, setActiveClaim = _a.setActiveClaim, setOpenModal = _a.setOpenModal;
    return (React.createElement("div", { className: "text-left px-8 mx-0 my-auto h-full overflow-y-scroll" },
        React.createElement("div", { className: "m-3 flex flex-row justify-between flex-wrap " },
            React.createElement("div", { className: "text-lg text-gray-500 self-center" }, "All Claims"),
            React.createElement("button", { className: "rounded-2xl bg-primary ripple text-primary font-bold tracking-lighter px-8 py-2", style: {
                    backgroundColor: shade(primaryColor ? primaryColor : '', 0.9)
                }, onClick: function () {
                    setPage(1);
                    setOpenModal(true);
                } }, "File a New Claim")), (_c = (_b = allClaimsData === null || allClaimsData === void 0 ? void 0 : allClaimsData.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 :
        _c.map(function (claim) { return (React.createElement(ClaimItem, { claim: claim, key: claim.claimId, setActiveClaim: setActiveClaim })); }),
        !((_d = allClaimsData === null || allClaimsData === void 0 ? void 0 : allClaimsData.data) === null || _d === void 0 ? void 0 : _d.data) && (React.createElement("div", { className: "text-lg text-gray-500 self-center text-center" }, "No claims filed currently."))));
}
