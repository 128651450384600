var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import getLatestPolicyOrQuote from '../utils/getLatestPolicyOrQuote';
var useLatestQuotesWithPolicies = function (getWithoutFilter) {
    if (getWithoutFilter === void 0) { getWithoutFilter = false; }
    var queryClient = useQueryClient();
    var customer = queryClient.getQueryData(['customer']);
    var quotesWithPolicies = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var quotes = (_b = (_a = customer === null || customer === void 0 ? void 0 : customer.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.Quotes;
        var policies = (_d = (_c = customer === null || customer === void 0 ? void 0 : customer.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.Policies;
        var products = (_g = (_f = (_e = customer === null || customer === void 0 ? void 0 : customer.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.SaasPlatform) === null || _g === void 0 ? void 0 : _g.Products;
        if (quotes && quotes.length > 0) {
            var quoteExternalIdMapper_1 = {};
            quotes.forEach(function (quote) {
                var _a;
                var quoteGroupKey = ((_a = quote.externalId) === null || _a === void 0 ? void 0 : _a.toString()) || '';
                if (quoteExternalIdMapper_1[quoteGroupKey]) {
                    quoteExternalIdMapper_1[quoteGroupKey].quotes.push(quote);
                }
                else {
                    quoteExternalIdMapper_1[quoteGroupKey] = {
                        quotes: [quote],
                        policy: null,
                        product: null,
                        MTDraftQuotes: quotes.filter(function (v) { return (v === null || v === void 0 ? void 0 : v.externalId) == quoteGroupKey && (v === null || v === void 0 ? void 0 : v.status) == 'MT Draft'; })
                    };
                }
                var policy = policies === null || policies === void 0 ? void 0 : policies.find(function (p) { return p.QuoteId === quote.id; });
                var product = products === null || products === void 0 ? void 0 : products.find(function (p) { return p.id === quote.ProductId; });
                if (policy && quoteExternalIdMapper_1[quoteGroupKey].policy === null) {
                    quoteExternalIdMapper_1[quoteGroupKey].policy = policy;
                }
                if (product && quoteExternalIdMapper_1[quoteGroupKey].product === null) {
                    quoteExternalIdMapper_1[quoteGroupKey].product = product;
                }
            });
            return Object.values(quoteExternalIdMapper_1).map(function (_a) {
                var _b;
                var quotes = _a.quotes, policy = _a.policy, product = _a.product, MTDraftQuotes = _a.MTDraftQuotes;
                var latestQuoteId = getLatestPolicyOrQuote(quotes, getWithoutFilter);
                var latestQuote = quotes === null || quotes === void 0 ? void 0 : quotes.filter(function (v) {
                    return ['bound', 'complete', 'pending', 'rejected'].includes(v.status);
                })[latestQuoteId];
                return __assign(__assign({}, latestQuote), { MTDraftQuotes: MTDraftQuotes, otherQuotes: ((_b = quotes === null || quotes === void 0 ? void 0 : quotes.filter(function (v) { return v.status == 'annulled'; })) === null || _b === void 0 ? void 0 : _b.length) > 0
                        ? quotes === null || quotes === void 0 ? void 0 : quotes.filter(function (v) { return v.status == 'annulled' && v.id !== (latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.id); })
                        : null, policy: policy, product: product });
            });
        }
        return [];
    }, [customer]);
    return quotesWithPolicies;
};
export default useLatestQuotesWithPolicies;
