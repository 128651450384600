import React from 'react';

import CloseIcon from './icons/CloseIcon';

//
import '../assets/styles/privacy-policy.css';

const PolicyNotice = ({ onClose, policyNotice }) => {
  return (
    <div className="fixed top-5 left-5 right-5 bottom-5 bg-backgroundColor z-50 rounded-lg privacy-policy overflow-hidden border border-slate-200">
      <div className="absolute top-4 left-4">
        <button
          onClick={onClose}
          className="rounded-full h-10 w-10 hover:bg-slate-200 flex items-center justify-center bg-white"
        >
          <CloseIcon />
        </button>
      </div>

      <div className="h-full overflow-y-scroll p-8">
        <h1 className="text-center">Privacy Notice</h1>
        <div
          className="max-w-3xl mx-auto"
          dangerouslySetInnerHTML={{ __html: policyNotice }}
        ></div>
      </div>
    </div>
  );
};

export default PolicyNotice;
