import React from 'react';
var PendingIcon = function () {
    return (React.createElement("svg", { width: 168, height: 102, viewBox: "0 0 168 102", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M160.5 15C164.366 15 167.5 18.134 167.5 22C167.5 25.866 164.366 29 160.5 29H120.5C124.366 29 127.5 32.134 127.5 36C127.5 39.866 124.366 43 120.5 43H142.5C146.366 43 149.5 46.134 149.5 50C149.5 53.866 146.366 57 142.5 57H132.326C127.452 57 123.5 60.134 123.5 64C123.5 66.5773 125.5 68.9107 129.5 71C133.366 71 136.5 74.134 136.5 78C136.5 81.866 133.366 85 129.5 85H46.5C42.634 85 39.5 81.866 39.5 78C39.5 74.134 42.634 71 46.5 71H7.5C3.63401 71 0.5 67.866 0.5 64C0.5 60.134 3.63401 57 7.5 57H47.5C51.366 57 54.5 53.866 54.5 50C54.5 46.134 51.366 43 47.5 43H22.5C18.634 43 15.5 39.866 15.5 36C15.5 32.134 18.634 29 22.5 29H62.5C58.634 29 55.5 25.866 55.5 22C55.5 18.134 58.634 15 62.5 15H160.5ZM160.5 43C164.366 43 167.5 46.134 167.5 50C167.5 53.866 164.366 57 160.5 57C156.634 57 153.5 53.866 153.5 50C153.5 46.134 156.634 43 160.5 43Z", fill: "#EFEFFE" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M107.172 14.0007L116.474 81.8434L117.309 88.6497C117.579 90.8424 116.019 92.8382 113.827 93.1074L55.2665 100.298C53.0738 100.567 51.078 99.0076 50.8088 96.815L41.7931 23.3874C41.6584 22.2911 42.4381 21.2932 43.5344 21.1586C43.5413 21.1578 43.5483 21.1569 43.5552 21.1562L48.4136 20.6112M52.3421 20.1705L56.9291 19.656L52.3421 20.1705Z", fill: "white" }),
        React.createElement("path", { d: "M108.41 13.8309C108.316 13.1469 107.686 12.6685 107.002 12.7623C106.318 12.856 105.84 13.4865 105.933 14.1705L108.41 13.8309ZM116.474 81.8434L117.714 81.6911C117.714 81.6853 117.713 81.6794 117.712 81.6736L116.474 81.8434ZM117.309 88.6497L118.55 88.4974L117.309 88.6497ZM113.827 93.1074L113.979 94.3481L113.827 93.1074ZM55.2665 100.298L55.4188 101.538L55.2665 100.298ZM50.8088 96.815L52.0495 96.6626L50.8088 96.815ZM43.5552 21.1562L43.6945 22.3984L43.5552 21.1562ZM48.5529 21.8534C49.239 21.7764 49.7327 21.1579 49.6558 20.4718C49.5788 19.7858 48.9603 19.292 48.2742 19.369L48.5529 21.8534ZM52.2028 18.9283C51.5167 19.0053 51.0229 19.6238 51.0999 20.3098C51.1768 20.9959 51.7954 21.4897 52.4814 21.4127L52.2028 18.9283ZM57.0685 20.8982C57.7545 20.8212 58.2483 20.2027 58.1714 19.5166C58.0944 18.8306 57.4759 18.3368 56.7898 18.4137L57.0685 20.8982ZM105.933 14.1705L115.235 82.0132L117.712 81.6736L108.41 13.8309L105.933 14.1705ZM115.233 81.9957L116.069 88.8021L118.55 88.4974L117.714 81.6911L115.233 81.9957ZM116.069 88.8021C116.254 90.3095 115.182 91.6816 113.674 91.8667L113.979 94.3481C116.857 93.9947 118.903 91.3753 118.55 88.4974L116.069 88.8021ZM113.674 91.8667L55.1141 99.057L55.4188 101.538L113.979 94.3481L113.674 91.8667ZM55.1141 99.057C53.6067 99.2421 52.2346 98.1701 52.0495 96.6626L49.5681 96.9673C49.9215 99.8452 52.5409 101.892 55.4188 101.538L55.1141 99.057ZM52.0495 96.6626L43.0337 23.2351L40.5524 23.5398L49.5681 96.9673L52.0495 96.6626ZM43.0337 23.2351C42.9833 22.824 43.2756 22.4498 43.6867 22.3993L43.3821 19.9179C41.6005 20.1367 40.3336 21.7582 40.5524 23.5398L43.0337 23.2351ZM43.6867 22.3993C43.6893 22.399 43.6919 22.3987 43.6945 22.3984L43.4159 19.914C43.4046 19.9152 43.3933 19.9165 43.3821 19.9179L43.6867 22.3993ZM43.6945 22.3984L48.5529 21.8534L48.2742 19.369L43.4159 19.914L43.6945 22.3984ZM52.4814 21.4127L57.0685 20.8982L56.7898 18.4137L52.2028 18.9283L52.4814 21.4127Z", className: 'fill-primary' }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M104.64 18.2686L113.06 79.7523L113.817 85.9205C114.061 87.9077 112.667 89.714 110.704 89.9551L58.2615 96.3941C56.2979 96.6352 54.5083 95.2198 54.2644 93.2327L46.1143 26.8562C45.9797 25.7599 46.7594 24.762 47.8557 24.6274L54.3433 23.8308", fill: "#EFEFFE" }),
        React.createElement("path", { d: "M61.4219 4C61.4219 2.48122 62.6531 1.25 64.1719 1.25H109.729C110.458 1.25 111.157 1.5395 111.673 2.05485L125.116 15.4898C125.632 16.0056 125.922 16.7053 125.922 17.4349V80C125.922 81.5188 124.691 82.75 123.172 82.75H64.1719C62.6531 82.75 61.4219 81.5188 61.4219 80V4Z", fill: "white", strokeWidth: "2.5", className: 'stroke-primary' }),
        React.createElement("path", { d: "M110.172 2.40234V13.9995C110.172 15.6564 111.515 16.9995 113.172 16.9995H121.105", strokeWidth: "2.5", strokeLinecap: "round", strokeLinejoin: "round", className: 'stroke-primary' }),
        React.createElement("path", { d: "M71.5 68H97.5M71.5 17H97.5H71.5ZM71.5 29H114.5H71.5ZM71.5 42H114.5H71.5ZM71.5 55H114.5H71.5Z", strokeWidth: "2.5", strokeLinecap: "round", strokeLinejoin: "round", className: 'stroke-primary' })));
};
export default PendingIcon;
