var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { useInsurance } from '../../context/Insurance';
import shade from '../../utils/shade';
import BackButton from '../common/BackButton';
import { getClaimTotal } from '../common/getClaimTotal';
var inputClass = 'w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline';
var inputClassTable = 'w-full px-3 py-2 text-sm leading-tight text-gray-700 appearance-none focus:outline-none';
var tableHeadingClass = "text-left px-3 py-2 text-sm leading-tight border-primary border-[1px]";
export default function ClaimEditor(_a) {
    var _b;
    var primaryColor = _a.primaryColor, customerID = _a.customerID, goBack = _a.goBack, setOpenModal = _a.setOpenModal, activeClaim = _a.activeClaim, setEditMode = _a.setEditMode, handleSubmit = _a.handleSubmit;
    var latestPolicy = useInsurance().latestPolicy;
    var _c = useState(activeClaim
        ? {
            customerId: activeClaim.customerId || customerID,
            policyId: activeClaim.policyId,
            title: 'asd',
            impact: activeClaim.impact,
            totalInvoice: activeClaim.totalInvoice
        }
        : {
            customerId: customerID,
            policyId: latestPolicy.policyId,
            title: '',
            impact: '',
            totalInvoice: 0
        }), newClaim = _c[0], setNewClaim = _c[1];
    var _d = useState(activeClaim && ((_b = activeClaim === null || activeClaim === void 0 ? void 0 : activeClaim.item) === null || _b === void 0 ? void 0 : _b.length) > 0
        ? activeClaim.item
        : [
            {
                name: '',
                issue: '',
                model: '',
                damageAmount: '',
                amountCurrency: 'GBP'
            },
        ]), claimItems = _d[0], setClaimItems = _d[1];
    useEffect(function () {
        return function () {
            setOpenModal(false);
            setEditMode && setEditMode(false);
        };
    }, []);
    useEffect(function () {
        var claimObj = __assign(__assign({}, newClaim), { item: __spreadArray([], claimItems, true) });
        var total = getClaimTotal(claimObj);
        setNewClaim(__assign(__assign({}, newClaim), { totalInvoice: total }));
    }, [claimItems]);
    var handleChange = function (e) {
        var _a;
        e.preventDefault();
        setNewClaim(__assign(__assign({}, newClaim), (_a = {}, _a[e.target.name] = e.target.value, _a)));
    };
    var handleChangeClaimItem = function (e, idx) {
        var _a;
        e.preventDefault();
        var items = __spreadArray([], claimItems, true);
        items[idx] = __assign(__assign({}, items[idx]), (_a = {}, _a[e.target.name] = e.target.value, _a));
        setClaimItems(items);
    };
    var addNewClaimItem = function (e) {
        e.preventDefault();
        var items = __spreadArray([], claimItems, true);
        items.push({
            name: '',
            issue: '',
            model: '',
            damageAmount: '',
            amountCurrency: 'GBP'
        });
        setClaimItems(items);
    };
    var fileClaim = function (e) {
        e.preventDefault();
        var claimObj = __assign(__assign({}, newClaim), { item: __spreadArray([], claimItems, true) });
        handleSubmit(claimObj);
    };
    return (React.createElement("form", { onSubmit: fileClaim, className: "py-4 flex flex-col text-left px-8 mx-0 my-auto h-full overflow-y-scroll" },
        React.createElement("div", { className: "grid grid-cols-3 mb-4" },
            React.createElement(BackButton, { onClick: goBack }),
            React.createElement("h1", { className: "text-lg font-bold text-black self-center text-center" }, activeClaim
                ? "Update Claim #".concat(activeClaim.claimId)
                : 'File New Claim')),
        React.createElement("div", { className: "mb-4 md:flex flex-row md:justify-between" },
            React.createElement("div", { className: "mb-4 md:mr-2 md:mb-0" },
                React.createElement("label", { className: "block mb-2 text-sm font-bold text-gray-700", htmlFor: "title" }, "Title"),
                React.createElement("input", { className: inputClass, type: "text", placeholder: "Title", name: "title", value: newClaim.title, onChange: handleChange })),
            React.createElement("div", { className: "mb-4 md:mr-2 md:mb-0" },
                React.createElement("label", { className: "block mb-2 text-sm font-bold text-gray-700", htmlFor: "impact" }, "Impact"),
                React.createElement("input", { className: inputClass, type: "text", placeholder: "Impact", name: "impact", value: newClaim.impact, onChange: handleChange })),
            React.createElement("div", { className: "mb-4 md:mr-2 md:mb-0" },
                React.createElement("label", { className: "block mb-2 text-sm font-bold text-gray-700", htmlFor: "totalInvoice" }, "Total Invoice"),
                React.createElement("input", { className: inputClass, type: "text", placeholder: "Total Invoice", name: "totalInvoice", value: newClaim.totalInvoice, onChange: handleChange, readOnly: true, disabled: true })),
            React.createElement("div", { className: "mb-4 md:mr-2 md:mb-0" },
                React.createElement("label", { className: "block mb-2 text-sm font-bold text-gray-700", htmlFor: "amountCurrency" }, "Amount Currency"),
                React.createElement("input", { className: inputClass, type: "text", placeholder: "Currency", name: "amountCurrency", value: "GBP", readOnly: true, disabled: true }))),
        React.createElement("div", { className: "flex flex-row justify-between w-full" },
            React.createElement("h2", { className: "font-bold mt-4" }, "Claim Items"),
            React.createElement("button", { className: "text-xl text-white bg-primary rounded px-2 my-2 mx-0 py-0 self-center", onClick: addNewClaimItem }, "+")),
        React.createElement("table", { className: "rounded-md" },
            React.createElement("thead", { style: { backgroundColor: shade(primaryColor, 0.9) } },
                React.createElement("tr", null,
                    React.createElement("th", { className: tableHeadingClass }, "Name"),
                    React.createElement("th", { className: tableHeadingClass }, "Description of Loss/Damage"),
                    React.createElement("th", { className: tableHeadingClass }, "Amount Claimed"))),
            React.createElement("tbody", null, claimItems.map(function (ci, idx) {
                return (React.createElement("tr", { className: "border-collapse" },
                    React.createElement("td", { className: "border-[#DDDDDD] border-[1px]" },
                        React.createElement("input", { className: inputClassTable, type: "text", placeholder: "Name", name: "name", value: ci.name, onChange: function (e) { return handleChangeClaimItem(e, idx); } })),
                    React.createElement("td", { className: "border-[#DDDDDD] border-[1px]" },
                        React.createElement("input", { className: inputClassTable, type: "text", placeholder: "Description of Loss/Damage", name: "issue", value: ci.issue, onChange: function (e) { return handleChangeClaimItem(e, idx); } })),
                    React.createElement("td", { className: "border-[#DDDDDD] border-[1px]" },
                        React.createElement("input", { className: inputClassTable, type: "number", placeholder: "Amount Claimed", name: "damageAmount", value: ci.damageAmount, onChange: function (e) { return handleChangeClaimItem(e, idx); } }))));
            }))),
        React.createElement("button", { className: "rounded-2xl bg-primary ripple text-primary w-full font-bold mt-2 mx-auto tracking-lighter px-8 py-4", style: { backgroundColor: shade(primaryColor, 0.9) }, type: "submit" }, activeClaim ? 'Update Claim' : 'File Claim')));
}
