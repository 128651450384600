import React from 'react';
var LimitPolicyPayment = function (_a) {
    var onClose = _a.onClose, paymentLink = _a.paymentLink;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "fixed z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-3xl bg-backgroundColor rounded-[16px] border border-slate-200" },
            React.createElement("div", { className: "p-6" },
                React.createElement("iframe", { className: "w-full aspect-square", src: paymentLink }))),
        React.createElement("div", { className: "h-screen w-screen z-10 fixed top-0 left-0 bg-black opacity-25", onClick: onClose })));
};
export default LimitPolicyPayment;
