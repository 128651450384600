import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
var Checkbox = function (_a) {
    var id = _a.id, label = _a.label, showError = _a.showError;
    var _b = useState(false), checked = _b[0], setChecked = _b[1];
    return (React.createElement("div", { className: "" },
        React.createElement("input", { id: id, name: id, type: "checkbox", required: true, checked: checked, onChange: function (e) { return setChecked(e.target.checked); }, className: "hidden" }),
        React.createElement("label", { htmlFor: id, className: "flex gap-4" },
            React.createElement("div", { className: "h-6 w-6 rounded-[4px] border-2 ".concat(checked ? 'border-primary' : 'border-[#919CC2]', " text-primary flex items-center justify-center") }, checked && (React.createElement(FontAwesomeIcon, { color: "currentColor", icon: faCheck, size: "lg" }))),
            label && React.createElement("p", { className: "text-sm flex-1" }, label)),
        showError && !checked && (React.createElement("div", { className: "text-red-500 text-xs mt-1" }, "Required"))));
};
export default Checkbox;
