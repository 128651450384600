import React, { createContext } from 'react';
import { compareAsc } from 'date-fns';

const getLatestPolicyOrQuote = (data) => {
  let latestIndex = 0;

  data?.forEach((v, i) => {
    const itemDate = new Date(v?.createdAt);
    const latestDate = new Date(data[latestIndex]?.createdAt);
    const result = compareAsc(itemDate, latestDate);
    if (result === 1) latestIndex = i;
  });
  return latestIndex;
};

function insuranceReducer(state, action) {
  switch (action.type) {
    case 'SET_POLICIES': {
      // set latest policy
      const policies = action.payload;
      const latestPolicyID = getLatestPolicyOrQuote(policies);
      return { ...state, policies, latestPolicyID };
    }
    case 'SET_QUOTES': {
      const quotes = action.payload;
      // get & set latest quote
      const latestQuoteID = getLatestPolicyOrQuote(quotes);

      return { ...state, quotes, latestQuoteID };
    }
    case 'CLEAR_DATA': {
      return { ...state, quotes: [], policies: [] };
    }
    case 'SET_LATEST_QUOTE': {
      var latestQuoteIndex = 0;

      state.quotes?.forEach((quote, i) => {
        const quoteDate = new Date(quote?.createdAt);
        const latestQuoteDate = new Date(
          dataQuote[latestQuoteIndex]?.createdAt,
        );
        const compare = compareAsc(quoteDate, latestQuoteDate);
        if (compare === 1) latestQuoteIndex = i;
      });

      return { ...state, latestQuoteID: latestQuoteIndex };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const initialState = {
  quotes: [],
  policies: [],
  insuranceLoading: false,
  latestQuoteID: null,
};

const InsuranceContext = createContext(initialState);

function InsuranceProvider({ children }) {
  const [state, dispatch] = React.useReducer(insuranceReducer, initialState);
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <InsuranceContext.Provider value={value}>
      {children}
    </InsuranceContext.Provider>
  );
}

function useInsurance() {
  const context = React.useContext(InsuranceContext);
  if (context === undefined) {
    throw new Error('useInsurance must be used within an InsuranceProvider');
  }

  let { quotes, policies, latestQuoteID, latestPolicyID } = context?.state;

  let latestQuote =
    quotes?.length > 0 && quotes[latestQuoteID] ? quotes[latestQuoteID] : null;
  let latestPolicy =
    policies?.length > 0 && policies[latestPolicyID]
      ? policies[latestPolicyID]
      : null;

  return {
    quotes,
    policies,
    latestQuote,
    latestPolicy,
    dispatch: context.dispatch,
  };
}

export { InsuranceProvider, useInsurance };
