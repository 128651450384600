import React from 'react';
import PendingIcon from '../../icons/PendingIcon';
//
import useWidgetStore from '../../../stores/widgetStore';
var LimitPending = function () {
    var _a = useWidgetStore(), setModalOpen = _a.setModalOpen, setSelectedQuote = _a.setSelectedQuote;
    return (React.createElement("div", { className: "h-full flex flex-col items-center justify-center sm:px-4" },
        React.createElement("div", { className: "flex flex-col items-center justify-center" },
            React.createElement(PendingIcon, null),
            React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" }, "We are preparing the best Quotes for your business"),
            React.createElement("span", { className: "text-[16px] mt-3" },
                "This may take some time. Please check back regularly.",
                React.createElement("br", null),
                "We will display a notice when we have some quotes for your review."),
            React.createElement("button", { className: "px-3 py-1.5 text-sm text-white bg-[#FD515E] font-bold border-2 border-[#FD515E] rounded-lg mt-6", onClick: function () {
                    setModalOpen(false);
                    setSelectedQuote(null);
                } }, "Understood"))));
};
export default LimitPending;
