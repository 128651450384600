import React from 'react';
import * as ReactDOM from 'react-dom';
import 'babel-polyfill';
import App from './App';
import "./main.css"
import { HtmlTagWrapper, resetFunction } from './html-tag-wrapper';

export const init = (customer, config) => {
  const el = document.getElementById('kayna')
  ReactDOM.unmountComponentAtNode(el)
  ReactDOM.render(<App customer={customer} config={config} />, el);
}

export { App }