import React from 'react';
import atbay from '../assets/At-Bay_Logo.png';
export function flattenObject(ob) {
    var toReturn = {};
    for (var i in ob) {
        if (!ob.hasOwnProperty(i))
            continue;
        if (typeof ob[i] == 'object' && ob[i] !== null) {
            var flatObject = flattenObject(ob[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x))
                    continue;
                toReturn[i + '.' + x] = flatObject[x];
            }
        }
        else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}
export var providerNameMapper = {
    atbay: {
        value: React.createElement("img", { src: atbay, alt: "atbay", className: "h-full" }),
        type: 'img'
    },
    cfc: 'CFC Underwriting Ltd.',
    tmhcc: 'Tokio Marine HCC'
};
