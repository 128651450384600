import React from 'react';
import useConfigStore from '../../stores/configStore';
import DefaultInput from '../Inputs/defaultInput';
var PolicyFormInput = function (_a) {
    var label = _a.label, questionKey = _a.questionKey, value = _a.value, min = _a.min, max = _a.max, handleChange = _a.handleChange, handleChangeDate = _a.handleChangeDate, handleBlur = _a.handleBlur, required = _a.required, inputType = _a.inputType, placeholder = _a.placeholder, options = _a.options, _b = _a.isHidden, isHidden = _b === void 0 ? false : _b, _c = _a.defaultChecked, defaultChecked = _c === void 0 ? false : _c, repeatsByValue = _a.repeatsByValue, errors = _a.errors, touched = _a.touched, helper = _a.helper;
    var style = useConfigStore().style;
    var theme = style.theme;
    // console.log('TOUCHED: ', touched);
    // console.log('ERROR: ', errors);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col items-start" },
            React.createElement(DefaultInput, { label: label, questionKey: questionKey, value: value, min: min, max: max, handleBlur: handleBlur, handleChange: handleChange, handleChangeDate: handleChangeDate, required: required, inputType: inputType, placeholder: placeholder, options: options, isHidden: isHidden, defaultChecked: defaultChecked, repeatsByValue: repeatsByValue, error: errors, helper: helper }),
            errors && (React.createElement("div", { className: "text-red-500 text-sm font-medium" }, errors)))));
};
export default PolicyFormInput;
