var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignature, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { capitalize } from 'lodash';
import LimitPolicyPayment from './LimitPolicyPayment';
import LimitPolicySign from './LimitPolicySign';
//
import { providerNameMapper } from '../../../utils/common';
import { printDate, printEffectiveDate } from '../../../utils/date';
import api from '../../../api';
import DownloadIcon from '../../icons/DownloadIcon';
var LimitPolicyPreview = function (_a) {
    var quoteId = _a.quoteId, policy = _a.policy;
    var _b = useState(false), paymentModalOpen = _b[0], setPaymentModalOpen = _b[1];
    var _c = useState(null), signDoc = _c[0], setSignDoc = _c[1];
    var isRequested = policy.status === 'bind_requested';
    var documents = useQuery({
        queryKey: ['quote', 'limit', 'policy-documents'],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.quote.listLimitPolicyDocuments({
                            quoteId: quoteId,
                            limitPolicyId: policy.id
                        })];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data.data];
                }
            });
        }); }
    }).data;
    var _d = useQuery({
        queryKey: ['docuseal', 'templates'],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.docuseal.getTemplates()];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data.data];
                }
            });
        }); }
    }).data, docusealTemplateIdMapper = _d === void 0 ? {} : _d;
    var closePaymentModal = function () {
        setPaymentModalOpen(false);
    };
    var openPaymentModal = function () {
        window.open(policy.payment_link, 'Complete Limit Payment', 'height=650,width=1280,scrollbars=no,toolbar=no,menubar=no,resizable=yes');
    };
    var total = useMemo(function () {
        var _a, _b;
        var prices = __spreadArray([
            {
                label: 'Premium',
                amount: (((_a = policy.premium) === null || _a === void 0 ? void 0 : _a.amount) || 0) / 100
            }
        ], (((_b = policy === null || policy === void 0 ? void 0 : policy.fees) === null || _b === void 0 ? void 0 : _b.map(function (fee) { return ({
            label: capitalize(fee.kind.replaceAll('_', ' ')),
            amount: fee.fee.amount / 100
        }); })) || []), true);
        var total = prices.reduce(function (prev, curr) {
            return prev + curr.amount;
        }, 0);
        return { prices: prices, total: total };
    }, [policy]).total;
    var documentsListing = useMemo(function () {
        var childDocuments = {};
        var parentDocuments = documents === null || documents === void 0 ? void 0 : documents.filter(function (doc) {
            var _a, _b;
            var isSigned = (_b = (_a = doc.name) === null || _a === void 0 ? void 0 : _a.endsWith) === null || _b === void 0 ? void 0 : _b.call(_a, '(Signed)');
            if (isSigned) {
                var parentName = doc.name.replace('(Signed)', '').trim().split(' ');
                parentName.pop();
                parentName = parentName.join(' ');
                if (childDocuments[parentName]) {
                    childDocuments[parentName].push(doc);
                }
                else {
                    childDocuments[parentName] = [doc];
                }
                return false;
            }
            else {
                return true;
            }
        });
        return (React.createElement("ul", { className: "grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-2 mb-12" }, parentDocuments === null || parentDocuments === void 0 ? void 0 : parentDocuments.map(function (document) {
            var _a, _b;
            var isSigned = Boolean((_a = policy === null || policy === void 0 ? void 0 : policy.signedDocuments) === null || _a === void 0 ? void 0 : _a[document.id]);
            var canBeSigned = ((_b = document.name) === null || _b === void 0 ? void 0 : _b.endsWith('(Signature Required)')) ||
                document.name === 'Limit Application';
            var nameParsed = document.name
                .replace('(Signature Required)', '')
                .trim();
            var children = childDocuments[nameParsed] || [];
            var hasChildren = children.length > 0;
            return (React.createElement(React.Fragment, null,
                React.createElement("li", { className: "".concat(hasChildren
                        ? 'rounded-[16px] bg-[#F5F7FA] border border-[#E9EDF7] p-3'
                        : '') },
                    React.createElement("div", { className: "flex items-center rounded-[16px] bg-[#F5F7FA] ".concat(hasChildren ? '' : 'border border-[#E9EDF7] p-3', " gap-3") },
                        React.createElement("span", { className: "text-[#1F2751] font-semibold flex-1" }, document.name),
                        canBeSigned && isRequested && (React.createElement(React.Fragment, null,
                            isSigned && (React.createElement("span", { className: "h-10 w-10 text-green-600 flex items-center justify-center" },
                                React.createElement(FontAwesomeIcon, { icon: faCheckCircle, size: "2x", color: 'currentColor' }))),
                            React.createElement("button", { className: "bg-primary h-10 w-10 rounded-[12px] flex items-center justify-center", onClick: function () {
                                    setSignDoc({
                                        id: document.id,
                                        url: document.download_url,
                                        name: nameParsed,
                                        templateId: docusealTemplateIdMapper[nameParsed]
                                    });
                                } },
                                React.createElement(FontAwesomeIcon, { icon: faSignature, size: "lg", color: 'white' })))),
                        React.createElement("a", { href: document.download_url, target: "_blank", className: "bg-primary h-10 w-10 rounded-[12px] flex items-center justify-center" },
                            React.createElement(DownloadIcon, null))),
                    hasChildren && (React.createElement("div", { className: "ml-6" },
                        React.createElement("h3", { className: "mt-3 mb-1 text-sm font-semibold" }, "Signed Entries"),
                        React.createElement("ul", { className: "flex flex-col gap-2" }, children.map(function (child) {
                            var nameParts = child.name.split(' ');
                            nameParts.pop();
                            var date = new Date(nameParts.pop());
                            return (React.createElement("li", { className: "flex items-center gap-3 relative" },
                                React.createElement("div", { className: "absolute top-1/2 -translate-y-1/2 -left-4 h-[6px] w-[6px] rounded-full bg-primary" }),
                                React.createElement("span", { className: "text-[#1F2751] font-semibold flex-1" }, date.toDateString()),
                                React.createElement("a", { href: child.download_url, target: "_blank", className: "bg-primary h-10 w-10 rounded-[12px] flex items-center justify-center" },
                                    React.createElement(DownloadIcon, null))));
                        })))))));
        })));
    }, [documents, policy]);
    var carrierName = providerNameMapper[policy.market] || capitalize(policy.market);
    var hasDocuments = (documents === null || documents === void 0 ? void 0 : documents.length) > 0;
    return (React.createElement(React.Fragment, null,
        paymentModalOpen && (React.createElement(LimitPolicyPayment, { paymentLink: policy.payment_link, onClose: closePaymentModal })),
        signDoc && (React.createElement(LimitPolicySign, { documentId: signDoc.id.toString(), documentName: signDoc.name, documentLink: signDoc.url, templateId: signDoc.templateId, quoteId: quoteId, limitPolicyId: policy.id, onClose: function () {
                setSignDoc(null);
            } })),
        React.createElement("div", { className: "p-6 text-left" },
            isRequested && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "mb-2 p-4 bg-red-100 border border-red-200 rounded-lg" },
                    React.createElement("p", null, "Thank you for your request to purchase this policy. Payment is required before we can process your request. A delay in payment may delay the effective date of your policy")),
                React.createElement("div", { className: "mb-8 p-4 bg-red-100 border border-red-200 rounded-lg font-semibold" },
                    React.createElement("p", null, "Please be aware that binding and/or policy documents may take up to 5 business days to arrive.")))),
            React.createElement("ul", { className: "grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-2 mb-12" },
                React.createElement("li", { className: "flex gap-2 h-14" },
                    React.createElement("h3", { className: "h-full text-[#1F2751] flex-1 p-4 bg-[#F8FAFE] rounded-tl-[12px] rounded-bl-[12px] flex items-center" }, "Carrier"),
                    carrierName.type === 'img' ? (React.createElement("div", { className: "flex justify-center bg-[#F8FAFE] rounded-tr-[12px] rounded-br-[12px] w-[230px] h-full py-[6px]" }, carrierName.value)) : (React.createElement("p", { className: "h-full text-[#1F2751] font-semibold w-[230px] bg-[#F8FAFE] rounded-tr-[12px] rounded-br-[12px] truncate p-4" }, carrierName))),
                React.createElement("li", { className: "flex gap-2 h-14" },
                    React.createElement("h3", { className: "h-full text-[#1F2751] flex-1 p-4 bg-[#F8FAFE] rounded-tl-[12px] rounded-bl-[12px] flex items-center" }, "Lines"),
                    React.createElement("p", { className: "h-full text-[#1F2751] font-semibold w-[230px] bg-[#F8FAFE] rounded-tr-[12px] rounded-br-[12px] truncate p-4" }, policy.lines.map(function (_a) {
                        var line = _a.line;
                        return capitalize(line);
                    }).join(', '))),
                React.createElement("li", { className: "flex gap-2 h-14" },
                    React.createElement("h3", { className: "h-full text-[#1F2751] flex-1 p-4 bg-[#F8FAFE] rounded-tl-[12px] rounded-bl-[12px] flex items-center" }, "Total Price"),
                    React.createElement("p", { className: "h-full text-[#1F2751] font-semibold w-[230px] bg-[#F8FAFE] rounded-tr-[12px] rounded-br-[12px] truncate p-4" },
                        "$",
                        total.toLocaleString())),
                React.createElement("li", { className: "flex gap-2 h-14" },
                    React.createElement("h3", { className: "h-full text-[#1F2751] flex-1 p-4 bg-[#F8FAFE] rounded-tl-[12px] rounded-bl-[12px] flex items-center" }, "Effective Date"),
                    React.createElement("p", { className: "h-full text-[#1F2751] font-semibold w-[230px] bg-[#F8FAFE] rounded-tr-[12px] rounded-br-[12px] truncate p-4" }, printEffectiveDate(policy.effective_date))),
                React.createElement("li", { className: "flex gap-2 h-14" },
                    React.createElement("h3", { className: "h-full text-[#1F2751] flex-1 p-4 bg-[#F8FAFE] rounded-tl-[12px] rounded-bl-[12px] flex items-center" }, "Quote Expiration Date"),
                    React.createElement("p", { className: "h-full text-[#1F2751] font-semibold w-[230px] bg-[#F8FAFE] rounded-tr-[12px] rounded-br-[12px] truncate p-4" }, printDate(policy.quote_expiration_date))),
                React.createElement("li", { className: "flex gap-2 h-14" },
                    React.createElement("h3", { className: "h-full text-[#1F2751] flex-1 p-4 bg-[#F8FAFE] rounded-tl-[12px] rounded-bl-[12px] flex items-center" }, "Aggregate Limit"),
                    React.createElement("p", { className: "h-full text-[#1F2751] font-semibold w-[230px] bg-[#F8FAFE] rounded-tr-[12px] rounded-br-[12px] truncate p-4" },
                        "$",
                        (policy.limit.amount / 100).toLocaleString()))),
            isRequested && (React.createElement("div", { className: "mb-12" },
                React.createElement("div", { className: "flex items-center mb-2" },
                    React.createElement("h2", { className: "text-[28px] font-bold text-[#252846]" }, "Payment")),
                React.createElement("button", { className: "flex items-center justify-center h-10 rounded-lg text-sm font-bold border border-primary text-primary transition-all w-[98px]", onClick: openPaymentModal }, "Pay"))),
            React.createElement("div", null,
                React.createElement("h2", { className: "text-[28px] font-bold mb-4 text-[#252846]" }, "Contingencies"),
                React.createElement("p", { className: "text-[#252846] mb-6" },
                    React.createElement("span", { className: "font-semibold" }, "Confirm the information stated below."),
                    ' ',
                    "This information has been provided to us as part of your application for this Policy. It is important that this information is correct as we may seek to avoid this Policy or reject a claim in the event of any reckless or deliberate non-disclosure or misrepresentation. If any of the information below is incorrect, please contact us as soon as is reasonably practicable."),
                React.createElement("ul", { className: "border border-[#E9EDF7] rounded-[16px] mb-12" }, policy.contingencies.map(function (contingency, i) {
                    var isLast = i === policy.contingencies.length - 1;
                    return (React.createElement("li", { key: contingency.id, className: "py-5 px-4 ".concat(isLast ? '' : 'border-b border-[#E9EDF7]') },
                        React.createElement("div", { className: "flex items-center gap-4 mb-2" },
                            contingency.pre_bind && (React.createElement("span", { className: "h-8 px-3 bg-[#E9EDF7] rounded-full text-sm font-semibold text-[#3B4265] flex items-center" }, "Pre Bind")),
                            React.createElement("span", { className: "font-semibold" }, printDate(contingency.created_at))),
                        React.createElement("p", { className: "text-sm" }, contingency.text)));
                })),
                React.createElement("h2", { className: "text-[28px] font-bold mb-4 text-[#252846]" }, "Documents"),
                hasDocuments ? documentsListing : React.createElement("p", null, "Loading..."),
                React.createElement("h2", { className: "text-[28px] font-bold mb-4 text-[#252846]" }, "Coverages"),
                React.createElement("div", { className: "w-full overflow-x-auto mb-12" },
                    React.createElement("table", { className: "w-full min-w-[754px]" },
                        React.createElement("thead", null,
                            React.createElement("tr", { className: "h-[52px] border-b-8 border-white" },
                                React.createElement("th", { className: "font-semibold text-base text-[#252846]" }, "What your policy covers"),
                                React.createElement("th", { className: "bg-[#F8FAFE] text-[#252846] rounded-tl-[12px] rounded-bl-[12px] border-white border-r-8 text-center font-semibold text-base" }, "Sublimit"),
                                React.createElement("th", { className: "bg-[#F8FAFE] text-[#252846] rounded-tr-[12px] rounded-br-[12px] text-center font-semibold text-base" }, "Subretention"))),
                        React.createElement("tbody", null, policy.lines.map(function (line) { return (React.createElement(React.Fragment, null, line.coverages.map(function (coverage) {
                            var sublimit = coverage.sublimit
                                ? (coverage.sublimit.amount / 100).toLocaleString()
                                : 'See document';
                            var subretention = coverage.subretention
                                ? (coverage.subretention.amount / 100).toLocaleString()
                                : 'See document';
                            return (React.createElement("tr", { className: "h-[52px] border-b-8 border-white" },
                                React.createElement("td", { className: "bg-[#F8FAFE] text-[#252846] rounded-tl-[12px] rounded-bl-[12px] font-semibold text-base px-4" }, coverage.name),
                                React.createElement("td", { className: "bg-[#F8FAFE] text-[#252846] text-center font-semibold border-x-8 border-white text-base" }, sublimit),
                                React.createElement("td", { className: "bg-[#F8FAFE] text-[#252846] rounded-tr-[12px] rounded-br-[12px] text-center font-semibold text-base" }, subretention)));
                        }))); })))),
                React.createElement("h2", { className: "text-[28px] font-bold mb-4 text-[#252846]" }, "Endorsements"),
                React.createElement("div", { className: "w-full overflow-x-auto" },
                    React.createElement("table", { className: "w-full min-w-[512px]" },
                        React.createElement("thead", null,
                            React.createElement("tr", { className: "h-[52px] border-b-8 border-white" },
                                React.createElement("th", { className: "font-semibold text-base text-[#252846]" }, "Endorsement Title"),
                                React.createElement("th", { className: "bg-[#F8FAFE] text-[#252846] rounded-[12px] text-center font-semibold text-base px-4" }, "Form Number in Specimen"))),
                        React.createElement("tbody", null, policy.endorsements.map(function (endorsement) { return (React.createElement("tr", { className: "h-[52px] border-b-8 border-white" },
                            React.createElement("td", { className: "bg-[#F8FAFE] text-[#252846] rounded-tl-[12px] rounded-bl-[12px] font-semibold text-base px-4 border-white border-r-8" }, endorsement.title),
                            React.createElement("td", { className: "bg-[#F8FAFE] text-[#252846] rounded-tr-[12px] rounded-br-[12px] text-center font-semibold text-base" }, endorsement.form_code))); }))))))));
};
export default LimitPolicyPreview;
