import React from 'react';
import { parseISO, addYears, format, addDays } from 'date-fns';
var getPolicyInfo = function (policyDetails, quoteDetails) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31;
    switch (policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerIdentifier) {
        case 'tigerLabs':
            return {
                policyDetails: [
                    {
                        name: 'Policy Number',
                        value: "".concat((_c = (_b = (_a = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _a === void 0 ? void 0 : _a.policy) === null || _b === void 0 ? void 0 : _b.number) === null || _c === void 0 ? void 0 : _c.split('-')[0], "-").concat((_f = (_e = (_d = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _d === void 0 ? void 0 : _d.policy) === null || _e === void 0 ? void 0 : _e.number) === null || _f === void 0 ? void 0 : _f.split('-')[1])
                    },
                    {
                        name: 'Policy Status',
                        value: (_h = (_g = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _g === void 0 ? void 0 : _g.policy) === null || _h === void 0 ? void 0 : _h.status
                    },
                    {
                        name: 'Agent ID',
                        value: (_m = (_l = (_k = (_j = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _j === void 0 ? void 0 : _j.policy) === null || _k === void 0 ? void 0 : _k.agent) === null || _l === void 0 ? void 0 : _l.id) === null || _m === void 0 ? void 0 : _m.split('-')[0]
                    },
                    {
                        name: 'Contract ID',
                        value: (_r = (_q = (_p = (_o = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _o === void 0 ? void 0 : _o.policy) === null || _p === void 0 ? void 0 : _p.contract) === null || _q === void 0 ? void 0 : _q.id) === null || _r === void 0 ? void 0 : _r.split('-')[0]
                    },
                    {
                        name: 'Active Period',
                        value: "".concat(format(parseISO((_t = (_s = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _s === void 0 ? void 0 : _s.policy) === null || _t === void 0 ? void 0 : _t.createdAt), 'MM/dd/yyyy'), " - ").concat(format(addYears(parseISO((_v = (_u = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _u === void 0 ? void 0 : _u.policy) === null || _v === void 0 ? void 0 : _v.createdAt), 1), 'MM/dd/yyyy'))
                    },
                    {
                        name: 'Renewal Type',
                        value: 'Automatic Renewal'
                    },
                ],
                quoteDetails: {
                    yearlyPremium: "\u20AC ".concat((_y = ((quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total) -
                        Number((_x = (_w = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _w === void 0 ? void 0 : _w.rateQuote) === null || _x === void 0 ? void 0 : _x.iptAmount))) === null || _y === void 0 ? void 0 : _y.toFixed(2)),
                    ipt: "\u20AC ".concat((_0 = (_z = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.partnerData) === null || _z === void 0 ? void 0 : _z.rateQuote) === null || _0 === void 0 ? void 0 : _0.iptAmount),
                    total: "\u20AC ".concat((_1 = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total) === null || _1 === void 0 ? void 0 : _1.toFixed(2))
                },
                policyDoc: {
                    policyDocuemnt: 'https://kayna-policy-documents.s3.amazonaws.com/policy-details.pdf',
                    certificateOfInsurance: 'https://kayna-policy-documents.s3.amazonaws.com/Certificate-Of-Insurance.pdf'
                }
            };
        case 'opal':
            return {
                policyDetails: [
                    {
                        name: 'Policy Number',
                        value: (_3 = (_2 = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _2 === void 0 ? void 0 : _2.policy) === null || _3 === void 0 ? void 0 : _3.policy_id
                    },
                    {
                        name: 'Policy Status',
                        value: (_5 = (_4 = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _4 === void 0 ? void 0 : _4.policy) === null || _5 === void 0 ? void 0 : _5.status_title
                    },
                    {
                        name: 'Active Period',
                        value: "".concat(format(parseISO((_8 = (_7 = (_6 = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _6 === void 0 ? void 0 : _6.policy) === null || _7 === void 0 ? void 0 : _7.dates) === null || _8 === void 0 ? void 0 : _8.effective), 'MM/dd/yyyy'), " - ").concat(format(addYears(addDays(parseISO((_11 = (_10 = (_9 = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _9 === void 0 ? void 0 : _9.policy) === null || _10 === void 0 ? void 0 : _10.dates) === null || _11 === void 0 ? void 0 : _11.expiry), 1), 1), 'MM/dd/yyyy'))
                    },
                    {
                        name: 'Renewal Type',
                        value: 'Manual Renewal'
                    },
                ],
                quoteDetails: {
                    yearlyPremium: "\u20AC ".concat((_18 = (((_14 = (_13 = (_12 = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _12 === void 0 ? void 0 : _12.policy) === null || _13 === void 0 ? void 0 : _13.price) === null || _14 === void 0 ? void 0 : _14.amount_premium) -
                        0.2 * ((_17 = (_16 = (_15 = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _15 === void 0 ? void 0 : _15.policy) === null || _16 === void 0 ? void 0 : _16.price) === null || _17 === void 0 ? void 0 : _17.amount_premium))) === null || _18 === void 0 ? void 0 : _18.toFixed(2)),
                    ipt: "\u20AC ".concat((_22 = ((((_21 = (_20 = (_19 = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _19 === void 0 ? void 0 : _19.policy) === null || _20 === void 0 ? void 0 : _20.price) === null || _21 === void 0 ? void 0 : _21.amount_premium) / 100) *
                        20)) === null || _22 === void 0 ? void 0 : _22.toFixed(2)),
                    total: "\u20AC ".concat((_26 = (_25 = (_24 = (_23 = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.partnerData) === null || _23 === void 0 ? void 0 : _23.policy) === null || _24 === void 0 ? void 0 : _24.price) === null || _25 === void 0 ? void 0 : _25.amount_premium) === null || _26 === void 0 ? void 0 : _26.toFixed(2))
                },
                policyDoc: {
                    policyDocuemnt: 'https://kayna-policy-documents.s3.amazonaws.com/policy-details.pdf',
                    certificateOfInsurance: 'https://kayna-policy-documents.s3.amazonaws.com/Certificate-Of-Insurance.pdf'
                }
            };
        default:
            return {
                policyDetails: [
                    {
                        name: 'Policy Number',
                        value: "".concat((_27 = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.id) === null || _27 === void 0 ? void 0 : _27.split('-')[0], "-").concat((_28 = policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.id) === null || _28 === void 0 ? void 0 : _28.split('-')[1])
                    },
                    {
                        name: 'Policy Status',
                        value: (React.createElement("div", { className: "flex items-center gap-x-3" },
                            React.createElement("span", { className: "w-3 h-3 rounded-full bg-[#42D58E]" }), policyDetails === null || policyDetails === void 0 ? void 0 :
                            policyDetails.insuranceStatus))
                    },
                    {
                        name: 'Payment Status',
                        value: policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.paymentStatus
                    },
                    {
                        name: 'Active Period',
                        value: "".concat((policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.createdAt) ? (format(new Date(policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.createdAt), 'MM/dd/yyyy')) : (React.createElement("i", null, "Loading...")), " - ").concat((policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.createdAt) ? (format(addYears(new Date(policyDetails === null || policyDetails === void 0 ? void 0 : policyDetails.createdAt), 1), 'MM/dd/yyyy')) : (React.createElement("i", null, "Loading...")))
                    },
                    {
                        name: 'Renewal Type',
                        value: 'Automatic Renewal'
                    },
                ],
                quoteDetails: {
                    yearlyPremium: "\u20AC ".concat((_29 = (Number(quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total) -
                        0.2 * Number(quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total))) === null || _29 === void 0 ? void 0 : _29.toFixed(2)),
                    ipt: "\u20AC ".concat((_30 = ((Number(quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total) / 100) * 20)) === null || _30 === void 0 ? void 0 : _30.toFixed(2)),
                    total: "\u20AC ".concat((_31 = quoteDetails === null || quoteDetails === void 0 ? void 0 : quoteDetails.total) === null || _31 === void 0 ? void 0 : _31.toFixed(2))
                },
                policyDoc: {
                    policyDocuemnt: 'https://kayna-policy-documents.s3.amazonaws.com/policy-details.pdf',
                    certificateOfInsurance: 'https://kayna-policy-documents.s3.amazonaws.com/Certificate-Of-Insurance.pdf'
                }
            };
    }
};
export default getPolicyInfo;
