// claims
import { instance } from "./index";
var CLAIM_URL = '/claim';
var getAll = function () {
    return instance({
        method: 'GET',
        url: CLAIM_URL
    });
};
var get = function (_a) {
    var claimID = _a.claimID;
    return instance({
        method: 'GET',
        url: "".concat(CLAIM_URL, "/").concat(claimID)
    });
};
var create = function (_a) {
    var customerId = _a.customerId, policyId = _a.policyId, title = _a.title, impact = _a.impact, totalInvoice = _a.totalInvoice, item = _a.item;
    return instance({
        method: 'POST',
        url: CLAIM_URL,
        data: {
            customerId: customerId,
            policyId: policyId,
            title: title,
            impact: impact,
            totalInvoice: totalInvoice,
            item: item
        }
    });
};
var update = function (claim) {
    return instance({
        method: 'PUT',
        url: "".concat(CLAIM_URL, "/").concat(claim === null || claim === void 0 ? void 0 : claim.claimId),
        data: {
            title: claim.title,
            impact: claim.impact,
            totalInvoice: claim.totalInvoice,
            item: claim.item
        }
    });
};
var archive = function (_a) {
    var claimID = _a.claimID;
    return instance({
        method: 'DELETE',
        url: "".concat(CLAIM_URL, "/").concat(claimID)
    });
};
var update_status = function (_a) {
    var claimID = _a.claimID, status = _a.status;
    return instance({
        method: 'PUT',
        url: "".concat(CLAIM_URL, "/status/").concat(claimID),
        data: {
            status: status
        }
    });
};
export default { get: get, create: create, update: update, archive: archive, update_status: update_status, getAll: getAll };
