import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar } from '@fortawesome/free-solid-svg-icons';
var MinimalInput = function (_a) {
    var label = _a.label, questionKey = _a.questionKey, value = _a.value, min = _a.min, max = _a.max, handleChange = _a.handleChange, handleChangeDate = _a.handleChangeDate, handleBlur = _a.handleBlur, _b = _a.required, required = _b === void 0 ? false : _b, inputType = _a.inputType, placeholder = _a.placeholder, options = _a.options, _c = _a.isHidden, isHidden = _c === void 0 ? false : _c, _d = _a.defaultChecked, defaultChecked = _d === void 0 ? false : _d, repeatsByValue = _a.repeatsByValue, _e = _a.error, error = _e === void 0 ? false : _e, helper = _a.helper;
    if (inputType === 'money') {
        value = Number("".concat(value).replace(/\D/g, '')).toLocaleString();
    }
    return (React.createElement("div", { className: "w-full" }, !isHidden &&
        (inputType == 'select' ? (React.createElement("div", { className: "flex flex-col w-full my-4" },
            React.createElement("label", { htmlFor: "".concat(questionKey), className: "text-xl text-[#252846] font-medium w-full text-left capitalize" },
                label,
                ":"),
            React.createElement("select", { value: value, name: "".concat(questionKey), id: "".concat(questionKey), key: questionKey, onBlur: handleBlur, onChange: handleChange, className: "w-full px-3 py-2.5 mt-2 text-xl leading-tight text-[#252846] bg-[#F8FAFE] border-2 border-[#E9EDF7] rounded-xl focus:outline-primary" },
                React.createElement("option", { disabled: true, selected: true }, placeholder), options === null || options === void 0 ? void 0 :
                options.map(function (opt) {
                    return React.createElement("option", { value: opt.value }, opt.caption);
                })),
            helper && (React.createElement("span", { className: "text-left text-sm block mt-3 leading-5" }, helper)))) : inputType == 'radio' ? (React.createElement("div", { className: "flex flex-col w-full my-4" },
            React.createElement("label", { htmlFor: "".concat(questionKey), className: "text-xl ".concat(error ? 'text-[#EA4335]' : 'text-[#252846]', " font-medium w-full text-left capitalize") },
                label,
                ":"),
            React.createElement("div", { className: "flex flex-col items-start gap-y-2 gap-x-4 mt-1" }, options === null || options === void 0 ? void 0 : options.map(function (opt) {
                var _a;
                return (React.createElement("div", { className: "flex items-center" },
                    React.createElement("input", { name: "".concat(questionKey), id: "".concat(questionKey), type: inputType, key: questionKey, value: opt.value, onBlur: handleBlur, defaultChecked: (_a = (defaultChecked === null || defaultChecked === void 0 ? void 0 : defaultChecked[questionKey]) === (opt === null || opt === void 0 ? void 0 : opt.value)) !== null && _a !== void 0 ? _a : true, onChange: handleChange, className: "w-4 h-4 ".concat(error
                            ? 'appearance-none border-[#EA4335] bg-[#EA4335] border-[1px] bg-opacity-30 rounded-full'
                            : 'accent-primary') }),
                    React.createElement("label", { className: "font-semibold text-lg ml-2" }, opt.caption)));
            })))) : inputType == 'date' ? (React.createElement("div", { className: "flex flex-col w-full my-4" },
            React.createElement("label", { htmlFor: "".concat(questionKey), className: "text-xl ".concat(error ? 'text-[#EA4335]' : 'text-[#252846]', " font-medium w-full text-left capitalize") },
                label,
                required && '*'),
            React.createElement(DatePicker, { showIcon: true, 
                // disabledKeyboardNavigation
                name: "".concat(questionKey), id: "".concat(questionKey), key: questionKey, placeholder: placeholder, className: "w-full pl-4 px-3 py-2.5 mt-2 text-xl leading-tight border-2 relative ".concat(error
                    ? 'border-[#EA4335] bg-[#FBD9D7] text-[#EA4335]'
                    : 'border-[#E9EDF7] bg-[#F8FAFE] text-[#252846]', " rounded-xl focus:outline-primary"), selected: value ? new Date(value) : new Date(), onChange: handleChangeDate, onBlur: handleBlur, minDate: min ? new Date(min) : undefined, maxDate: max ? new Date(max) : undefined, icon: React.createElement("div", { className: "absolute top-0 right-0 w-6 h-5/6 z-50 flex items-center" },
                    React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                        React.createElement("path", { d: "M8 5.75C7.59 5.75 7.25 5.41 7.25 5V2C7.25 1.59 7.59 1.25 8 1.25C8.41 1.25 8.75 1.59 8.75 2V5C8.75 5.41 8.41 5.75 8 5.75Z", fill: "#252846" }),
                        React.createElement("path", { d: "M16 5.75C15.59 5.75 15.25 5.41 15.25 5V2C15.25 1.59 15.59 1.25 16 1.25C16.41 1.25 16.75 1.59 16.75 2V5C16.75 5.41 16.41 5.75 16 5.75Z", fill: "#252846" }),
                        React.createElement("path", { d: "M20.5 9.83984H3.5C3.09 9.83984 2.75 9.49984 2.75 9.08984C2.75 8.67984 3.09 8.33984 3.5 8.33984H20.5C20.91 8.33984 21.25 8.67984 21.25 9.08984C21.25 9.49984 20.91 9.83984 20.5 9.83984Z", fill: "#252846" }),
                        React.createElement("path", { d: "M16 22.75H8C4.35 22.75 2.25 20.65 2.25 17V8.5C2.25 4.85 4.35 2.75 8 2.75H16C19.65 2.75 21.75 4.85 21.75 8.5V17C21.75 20.65 19.65 22.75 16 22.75ZM8 4.25C5.14 4.25 3.75 5.64 3.75 8.5V17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17V8.5C20.25 5.64 18.86 4.25 16 4.25H8Z", fill: "#252846" }),
                        React.createElement("path", { d: "M8.5 14.5C8.37 14.5 8.24 14.47 8.12 14.42C8 14.37 7.89001 14.3 7.79001 14.21C7.70001 14.11 7.62999 14 7.57999 13.88C7.52999 13.76 7.5 13.63 7.5 13.5C7.5 13.24 7.61001 12.98 7.79001 12.79C7.89001 12.7 8 12.63 8.12 12.58C8.3 12.5 8.50001 12.48 8.70001 12.52C8.76001 12.53 8.82 12.55 8.88 12.58C8.94 12.6 9 12.63 9.06 12.67C9.11 12.71 9.15999 12.75 9.20999 12.79C9.24999 12.84 9.29999 12.89 9.32999 12.94C9.36999 13 9.40001 13.06 9.42001 13.12C9.45001 13.18 9.47001 13.24 9.48001 13.3C9.49001 13.37 9.5 13.43 9.5 13.5C9.5 13.76 9.38999 14.02 9.20999 14.21C9.01999 14.39 8.76 14.5 8.5 14.5Z", fill: "#252846" }),
                        React.createElement("path", { d: "M12 14.5008C11.74 14.5008 11.48 14.3908 11.29 14.2108C11.25 14.1608 11.21 14.1108 11.17 14.0608C11.13 14.0008 11.1 13.9409 11.08 13.8809C11.05 13.8209 11.03 13.7609 11.02 13.7009C11.01 13.6309 11 13.5708 11 13.5008C11 13.3708 11.03 13.2408 11.08 13.1208C11.13 13.0008 11.2 12.8909 11.29 12.7909C11.57 12.5109 12.02 12.4208 12.38 12.5808C12.51 12.6308 12.61 12.7009 12.71 12.7909C12.89 12.9809 13 13.2408 13 13.5008C13 13.5708 12.99 13.6309 12.98 13.7009C12.97 13.7609 12.95 13.8209 12.92 13.8809C12.9 13.9409 12.87 14.0008 12.83 14.0608C12.79 14.1108 12.75 14.1608 12.71 14.2108C12.61 14.3008 12.51 14.3709 12.38 14.4209C12.26 14.4709 12.13 14.5008 12 14.5008Z", fill: "#252846" }),
                        React.createElement("path", { d: "M8.5 18.0009C8.37 18.0009 8.24 17.9709 8.12 17.9209C8 17.8709 7.89001 17.8008 7.79001 17.7108C7.70001 17.6108 7.62999 17.5109 7.57999 17.3809C7.52999 17.2609 7.5 17.1309 7.5 17.0009C7.5 16.7409 7.61001 16.4809 7.79001 16.2909C7.89001 16.2009 8 16.1308 8.12 16.0808C8.49 15.9208 8.92999 16.0109 9.20999 16.2909C9.24999 16.3409 9.29999 16.3909 9.32999 16.4409C9.36999 16.5009 9.40001 16.5608 9.42001 16.6208C9.45001 16.6808 9.47001 16.7408 9.48001 16.8108C9.49001 16.8708 9.5 16.9409 9.5 17.0009C9.5 17.2609 9.38999 17.5208 9.20999 17.7108C9.01999 17.8908 8.76 18.0009 8.5 18.0009Z", fill: "#252846" }))) }))) : (React.createElement("div", { className: "flex flex-col w-full my-4" },
            React.createElement("label", { htmlFor: "".concat(questionKey), className: "text-xl ".concat(error ? 'text-[#EA4335]' : 'text-[#252846]', " font-medium w-full text-left capitalize") },
                label,
                required && '*'),
            React.createElement("div", { className: "flex gap-x-2 items-center mt-2" },
                inputType === 'money' && (React.createElement("i", { className: "block h-6 w-6" },
                    React.createElement(FontAwesomeIcon, { icon: faDollar, size: "lg", color: '#252846' }))),
                React.createElement("input", { name: "".concat(questionKey), id: "".concat(questionKey), type: inputType !== 'money' ? inputType : 'text', key: questionKey, className: "w-full px-3 py-2.5 text-xl leading-tight border-2 ".concat(error
                        ? 'border-[#EA4335] bg-[#FBD9D7] text-[#EA4335] placeholder-[#EA4335]'
                        : 'border-[#E9EDF7] bg-[#F8FAFE] text-[#252846] placeholder-gray-500', " rounded-xl focus:outline-primary"), value: inputType == 'date'
                        ? value
                            ? new Date(value).toISOString().split('T')[0]
                            : value
                        : value, placeholder: placeholder, onChange: function (e) {
                        if (inputType === 'money') {
                            e.target.value = Number("".concat(e.target.value).replace(/\D/g, '')).toLocaleString();
                        }
                        handleChange(e);
                    }, onBlur: handleBlur })),
            helper && (React.createElement("span", { className: "text-left text-sm block mt-3 leading-5" }, helper)))))));
};
export default MinimalInput;
