import React from 'react';
import ErrorIcon from './icons/ErrorIcon';
import getStyles from '../utils/getStyles';
var Error = function (_a) {
    var _b;
    var style = _a.style;
    return (React.createElement("div", { className: "m-auto flex flex-col align-center justify-center rounded-3xl kayna-app bg-white p-6", style: {
            width: style === null || style === void 0 ? void 0 : style.width,
            height: style === null || style === void 0 ? void 0 : style.height,
            border: "1px solid ".concat((_b = getStyles(style === null || style === void 0 ? void 0 : style.theme)) === null || _b === void 0 ? void 0 : _b.widgetBorderColor)
        } },
        React.createElement("div", { className: "flex flex-col gap-y-6 items-center justify-center h-full" },
            React.createElement(ErrorIcon, null),
            React.createElement("span", { className: "text-2xl font-bold text-center w-full text-[#1F2751]" }, "Oops something went wrong!"),
            React.createElement("span", { className: "text-md font-semibold text-center w-full text-[#1F2751] mb-4" }, "Please make sure you have provided correct widget config for the product"))));
};
export default Error;
