import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import useConfigStore from '../../stores/configStore';
import useWidgetStore from '../../stores/widgetStore';
var BreadCrumbs = function (_a) {
    var links = _a.links;
    var _b = useWidgetStore(), setCurrentTab = _b.setCurrentTab, setModalOpen = _b.setModalOpen;
    var style = useConfigStore().style;
    var primaryColor = style.primaryColor;
    var updateTab = function (e, number) {
        e.preventDefault();
        setCurrentTab(number);
    };
    return (React.createElement("div", { className: "flex items-center justify-center gap-x-2 mb-4 self-start" }, links.map(function (link, index) {
        return (React.createElement(React.Fragment, null,
            React.createElement("a", { className: "text-sm font-extrabold ".concat(link.active ? 'text-textColor' : 'text-primary', " underline decoration-primary cursor-pointer"), onClick: function (e) {
                    if (link.closeModal) {
                        setModalOpen(false);
                    }
                    updateTab(e, link.link);
                } }, link.name),
            index !== (links === null || links === void 0 ? void 0 : links.length) - 1 && (React.createElement(FontAwesomeIcon, { icon: faChevronRight, size: "xs", color: primaryColor }))));
    })));
};
export default BreadCrumbs;
