export var getClaimTotal = function (claim, formatted) {
    var _a;
    var sum = 0;
    (_a = claim.item) === null || _a === void 0 ? void 0 : _a.map(function (ci) {
        var itemAmount = Number(ci === null || ci === void 0 ? void 0 : ci.damageAmount) > 0 ? Number(ci === null || ci === void 0 ? void 0 : ci.damageAmount) : 0;
        sum += itemAmount;
    });
    return formatted
        ? new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP'
        }).format(sum)
        : sum;
};
