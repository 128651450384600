import { create } from 'zustand';
export var CurrentTabEnum;
(function (CurrentTabEnum) {
    CurrentTabEnum[CurrentTabEnum["POLICY"] = 1] = "POLICY";
    CurrentTabEnum[CurrentTabEnum["CLAIMS"] = 2] = "CLAIMS";
    CurrentTabEnum[CurrentTabEnum["NOTIFICATIONS"] = 3] = "NOTIFICATIONS";
    CurrentTabEnum[CurrentTabEnum["QUOTE_INFO"] = 4] = "QUOTE_INFO";
    CurrentTabEnum[CurrentTabEnum["POLICY_INFO"] = 5] = "POLICY_INFO";
    CurrentTabEnum[CurrentTabEnum["COVERAGES"] = 6] = "COVERAGES";
    CurrentTabEnum[CurrentTabEnum["POLICY_INFO_LAST_STEP"] = 7] = "POLICY_INFO_LAST_STEP";
    CurrentTabEnum[CurrentTabEnum["QUOTE_PENDING"] = 8] = "QUOTE_PENDING";
    CurrentTabEnum[CurrentTabEnum["POLICY_CHANGES"] = 9] = "POLICY_CHANGES";
    CurrentTabEnum[CurrentTabEnum["VIEW_POLICY_CHANGES"] = 10] = "VIEW_POLICY_CHANGES";
})(CurrentTabEnum || (CurrentTabEnum = {}));
var useWidgetStore = create(function (set) { return ({
    selectedQuote: null,
    selectedPolicy: null,
    currentTab: CurrentTabEnum.POLICY,
    showNotification: false,
    isFormActive: false,
    modalOpen: false,
    answers: null,
    applicationDataId: null,
    notificationSwitched: false,
    createQuoteSubmitted: false,
    setSelectedQuote: function (val) {
        set(function () { return ({
            selectedQuote: val
        }); });
    },
    setNotificationSwitched: function (notificationTab) {
        set(function (state) { return ({
            notificationSwitched: notificationTab
        }); });
    },
    setApplicationDataId: function (applicationDataRef) {
        set(function (state) { return ({
            applicationDataId: applicationDataRef
        }); });
    },
    setAnswers: function (ans) {
        set(function (state) { return ({
            answers: ans
        }); });
    },
    setSelectedPolicy: function (_a) {
        var policyId = _a.policyId, externalId = _a.externalId;
        set(function (state) { return ({
            selectedPolicy: {
                policyId: policyId,
                externalId: externalId
            }
        }); });
    },
    setFormActive: function (isFormActive) {
        set(function (state) { return ({
            isFormActive: isFormActive
        }); });
    },
    resetWidgetState: function () {
        set(function (state) { return ({
            currentTab: CurrentTabEnum.POLICY,
            showNotification: false
        }); });
    },
    setCurrentTab: function (tab) {
        set(function (state) { return ({
            currentTab: tab
        }); });
    },
    setShowNotification: function (notif) {
        set(function (state) { return ({
            showNotification: notif
        }); });
    },
    setModalOpen: function (open) {
        set(function (state) { return ({
            modalOpen: open
        }); });
    },
    toggleModal: function () {
        set(function (state) { return ({
            modalOpen: !state.modalOpen
        }); });
    },
    setCreateQuoteSubmitted: function (val) {
        set(function () { return ({
            createQuoteSubmitted: val
        }); });
    }
}); });
export default useWidgetStore;
