var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
var useCustomerStore = create(function (set) { return ({
    extCustomerID: null,
    id: null,
    productId: null,
    items: {
        requiredParams: {},
        optionalParams: {}
    },
    applicationFormData: {},
    extCustomerName: undefined,
    extCustomerEmail: undefined,
    setExternalCustomer: function (config) {
        set(function (state) { return (__assign(__assign({}, state), config)); });
    }
}); });
export default useCustomerStore;
