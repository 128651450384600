import React from 'react';
import useWidgetStore from '../../../../stores/widgetStore';
import QuoteRejectedIcon from '../../../icons/QuoteRejectedIcon';
import ModalCloseIcon from '../../../icons/ModalCloseIcon';
var QuoteRejected = function (_a) {
    var _b = _a.headingText, headingText = _b === void 0 ? 'Oops! It seems that the quote has been rejected' : _b, _c = _a.contentText, contentText = _c === void 0 ? 'The information provided during the request does not match the records.' : _c, _d = _a.buttonText, buttonText = _d === void 0 ? 'Restart Request' : _d, _e = _a.closeButtonText, closeButtonText = _e === void 0 ? '' : _e, setRestartRequest = _a.setRestartRequest, _f = _a.icon, icon = _f === void 0 ? React.createElement(QuoteRejectedIcon, null) : _f, _g = _a.buttonClasses, buttonClasses = _g === void 0 ? 'px-3 py-1.5 text-sm text-white bg-[#FD515E] font-bold border-2 border-[#FD515E] rounded-lg ' : _g, _h = _a.moreDetails, moreDetails = _h === void 0 ? null : _h, _j = _a.openInModal, openInModal = _j === void 0 ? false : _j;
    var _k = useWidgetStore(), setModalOpen = _k.setModalOpen, setFormActive = _k.setFormActive;
    var closeModal = function () {
        setModalOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        openInModal && (React.createElement("div", { className: "fixed h-full w-full top-0 left-0 bg-black opacity-20" })),
        React.createElement("div", { className: openInModal ? 'fixed top-10 left-0 right-0' : '' },
            React.createElement("div", { className: "flex flex-col items-center justify-start ".concat(openInModal
                    ? 'relative p-6 max-w-[568px] mx-auto bg-white rounded-[16px]'
                    : '') },
                openInModal && (React.createElement("div", { className: "absolute top-6 right-6" },
                    React.createElement("button", { className: "h-6 w-6 cursor-pointer", onClick: closeModal },
                        React.createElement(ModalCloseIcon, null)))),
                React.createElement("div", { className: "flex flex-col items-center justify-center ".concat(openInModal ? '' : 'max-w-[580px]') },
                    icon,
                    headingText && (React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" }, headingText)),
                    contentText && React.createElement("span", { className: "mt-2" }, contentText),
                    moreDetails && React.createElement("div", { className: "w-full mt-8" }, moreDetails),
                    React.createElement("div", { className: "mt-8" },
                        closeButtonText && (React.createElement("button", { className: "".concat(buttonClasses, " mr-4 !border-[#C8CEE3] text-[#C8CEE3]"), onClick: closeModal }, closeButtonText)),
                        React.createElement("button", { className: buttonClasses, onClick: function () {
                                setModalOpen(true);
                                setRestartRequest(true);
                                setFormActive(true);
                            } }, buttonText)))))));
};
export default QuoteRejected;
