import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
//
import useLatestQuotesWithPolicies from '../../../hooks/useLatestQuotesWithPolicies';
import useWidgetStore, { CurrentTabEnum } from '../../../stores/widgetStore';
var NotificationListing = function (_a) {
    var onSelectPolicy = _a.onSelectPolicy;
    var latestQuotesWithPolicies = useLatestQuotesWithPolicies();
    var _b = useWidgetStore(), setSelectedPolicy = _b.setSelectedPolicy, setCurrentTab = _b.setCurrentTab, setModalOpen = _b.setModalOpen;
    return (React.createElement("div", { className: "flex flex-col h-full" },
        React.createElement("ul", { className: "flex-1" }, latestQuotesWithPolicies
            .filter(function (quote) {
            var _a, _b;
            return (quote.MTDraftQuotes && ((_a = quote.MTDraftQuotes) === null || _a === void 0 ? void 0 : _a.length) > 0) ||
                (quote.otherQuotes && ((_b = quote.otherQuotes) === null || _b === void 0 ? void 0 : _b.length) > 0);
        })
            .map(function (quote) {
            var _a, _b;
            var quoteIdParts = ((_a = quote === null || quote === void 0 ? void 0 : quote.policy) === null || _a === void 0 ? void 0 : _a.id)
                ? quote.policy.id.split('-')
                : quote.id.split('-');
            var quoteIdDisplay = "".concat(quoteIdParts[0], "-").concat(quoteIdParts[1]);
            var displayDate = '';
            var productName = (_b = quote.Product) === null || _b === void 0 ? void 0 : _b.productName;
            if (quote.status !== 'complete') {
                var quoteDate = new Date(quote.createdAt);
                quoteDate.setFullYear(quoteDate.getFullYear() + 1);
                displayDate = "".concat(quoteDate.getDate(), "/").concat(quoteDate.getMonth() + 1, "/").concat(quoteDate.getFullYear());
            }
            else {
                displayDate = 'In Progress';
            }
            return (React.createElement("li", { key: quote.id, className: "[&:not(:last-child)]:mb-5" },
                React.createElement("button", { className: "flex h-[84px] items-center w-full px-6", onClick: function () {
                        var _a;
                        setSelectedPolicy({
                            policyId: (_a = quote === null || quote === void 0 ? void 0 : quote.policy) === null || _a === void 0 ? void 0 : _a.id,
                            externalId: quote === null || quote === void 0 ? void 0 : quote.externalId
                        });
                        setCurrentTab(CurrentTabEnum.POLICY_CHANGES);
                        setModalOpen(true);
                        // onSelectPolicy(quote.id);
                    } },
                    React.createElement("div", { className: "flex-1 text-left" },
                        React.createElement("h4", { className: "text-[#202851] text-xs font-semibold" }, "Policy Number:"),
                        React.createElement("h3", { className: "font-bold text-lg leading-[26px]" }, quoteIdDisplay),
                        productName && (React.createElement("h4", { className: "mt-1 text-sm leading-[17px]" }, productName))),
                    React.createElement("div", { className: "flex items-center gap-2" },
                        React.createElement(FontAwesomeIcon, { icon: faCircleExclamation, color: "#EA4335", size: "lg" })))));
        }))));
};
export default NotificationListing;
