var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import QuoteLoading from './QuoteLoading';
import LimitForm from './LimitForm';
import LimitPolicySelection from './LimitPolicySelection';
import useWidgetStore, { CurrentTabEnum } from '../../../stores/widgetStore';
import useCustomerStore from '../../../stores/customerStore';
import api from '../../../api';
var LimitFlow = function (_a) {
    var _b, _c, _d;
    var latestQuote = _a.latestQuote;
    var _e = useState(false), bindLoading = _e[0], setBindLoading = _e[1];
    var _f = useState(null), previewPolicyId = _f[0], setPreviewPolicyId = _f[1];
    var _g = useWidgetStore(), setModalOpen = _g.setModalOpen, setSelectedQuote = _g.setSelectedQuote, setCurrentTab = _g.setCurrentTab, setCreateQuoteSubmitted = _g.setCreateQuoteSubmitted;
    var applicationFormData = useCustomerStore().applicationFormData;
    var queryClient = useQueryClient();
    useEffect(function () {
        setCreateQuoteSubmitted(false);
    }, []);
    var bindPolicy = useMutation({
        mutationFn: function (props) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setBindLoading(true);
                        return [4 /*yield*/, api.quote.bindLimitApplicationPolicy(props)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries(['customer']);
            setCurrentTab(CurrentTabEnum.POLICY_INFO);
        }
    });
    var bindPolicyHandler = function (policyId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, bindPolicy.mutateAsync({
                        quoteId: latestQuote.id,
                        limitPolicyId: policyId
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var answers = useMemo(function () {
        var _a, _b, _c;
        var formDataAnswers = (_b = (_a = applicationFormData === null || applicationFormData === void 0 ? void 0 : applicationFormData[latestQuote.externalId || '']) === null || _a === void 0 ? void 0 : _a.applicationDetails) === null || _b === void 0 ? void 0 : _b.others;
        var submittedAnswers = (_c = latestQuote.partnerData) === null || _c === void 0 ? void 0 : _c.moreQuestionAnswers;
        return __assign(__assign({}, formDataAnswers), submittedAnswers);
    }, [applicationFormData, latestQuote]);
    var closeModal = function () {
        setModalOpen(false);
        setSelectedQuote(null);
    };
    var isSubmitting = Boolean((_b = latestQuote.partnerData) === null || _b === void 0 ? void 0 : _b.submitting);
    var isSubmitted = Boolean((_d = (_c = latestQuote.partnerData) === null || _c === void 0 ? void 0 : _c.shareableApplication) === null || _d === void 0 ? void 0 : _d.submitted_at);
    if (bindLoading) {
        return React.createElement(QuoteLoading, null);
    }
    return (React.createElement("div", { className: "pb-5 my-auto absolute h-full w-full top-0  overflow-x-hidden" }, latestQuote.partnerData &&
        (isSubmitted || isSubmitting ? (React.createElement(LimitPolicySelection, { latestQuote: latestQuote, previewPolicyId: previewPolicyId, setPreviewPolicyId: setPreviewPolicyId, onBindPolicy: bindPolicyHandler, closeModal: closeModal, isSubmitted: isSubmitted })) : (React.createElement(LimitForm, { latestQuote: latestQuote, questions: latestQuote.partnerData.questions, answers: answers })))));
};
export default LimitFlow;
