var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import useConfigStore from '../../../stores/configStore';
import useWidgetStore from '../../../stores/widgetStore';
import CheckIcon from '../../icons/CheckIcon';
var QuoteLoading = function (_a) {
    var _b = _a.showAnimated, showAnimated = _b === void 0 ? false : _b, _c = _a.textHeading, textHeading = _c === void 0 ? 'Finding the best insurance policy for you...' : _c, _d = _a.textOne, textOne = _d === void 0 ? 'Reviewing your information' : _d, _e = _a.textTwo, textTwo = _e === void 0 ? 'Outlining the terms and conditions of the insurance policy' : _e, _f = _a.justifyCenter, justifyCenter = _f === void 0 ? false : _f;
    var style = useConfigStore().style;
    var modalOpen = useWidgetStore().modalOpen;
    var theme = style.theme;
    var _g = useState({
        animationOne: false,
        animationTwo: false
    }), triggerAnimation = _g[0], setTriggerAnimation = _g[1];
    useEffect(function () {
        if (!triggerAnimation.animationOne) {
            setTimeout(function () {
                setTriggerAnimation(__assign(__assign({}, triggerAnimation), { animationOne: true }));
            }, 2000);
        }
        else if (!triggerAnimation.animationTwo) {
            setTimeout(function () {
                setTriggerAnimation(__assign(__assign({}, triggerAnimation), { animationTwo: true }));
            }, 2000);
        }
    }, [triggerAnimation]);
    return (React.createElement("div", { className: "rounded-3xl mx-2 mt-30 px-12 pb-8 pt-4 bg-backgroundColor my-2 flex flex-col h-full ".concat(!showAnimated || justifyCenter ? 'justify-center' : 'justify-start', " items-center gap-y-4") },
        React.createElement("div", null,
            React.createElement("div", { className: "loader" })),
        showAnimated && (React.createElement(React.Fragment, null,
            textHeading && (React.createElement("span", { className: "text-xl font-bold text-[#1F2751]" }, textHeading)),
            modalOpen && (React.createElement(React.Fragment, null,
                textOne && (React.createElement("div", { className: "flex items-start gap-x-2 transition-opacity ease-in duration-700 ".concat(triggerAnimation.animationOne ? 'opacity-100' : 'opacity-0') },
                    React.createElement(CheckIcon, null),
                    React.createElement("span", { className: "text-lg text-[#1F2751]" }, textOne))),
                textTwo && (React.createElement("div", { className: "flex items-start gap-x-2 transition-opacity  ease-in duration-700 ".concat(triggerAnimation.animationTwo ? 'opacity-100' : 'opacity-0') },
                    React.createElement(CheckIcon, null),
                    React.createElement("span", { className: "text-lg text-[#1F2751]" }, textTwo)))))))));
};
export default QuoteLoading;
