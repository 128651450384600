export var printDate = function (date) {
    return new Date(date).toLocaleDateString('en-us', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    });
};
export var printEffectiveDate = function (dateString) {
    var date = new Date(dateString);
    return "".concat(new Date(date).toLocaleDateString('en-us', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    }), " to ").concat(date.getFullYear() + 1);
};
