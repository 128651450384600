var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import Timeline from './Timeline';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, BarElement, RadialLinearScale, ArcElement, } from 'chart.js';
import { Line, Bar, PolarArea, Radar } from 'react-chartjs-2';
import useConfigStore from '../../../stores/configStore';
import useCustomerStore from '../../../stores/customerStore';
import useWidgetStore, { CurrentTabEnum } from '../../../stores/widgetStore';
import PolicyStepsHeader from '../../common/PolicyStepsHeader';
import api from '../../../api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import QuoteLoading from '../Policy/QuoteLoading';
import randomColor from 'randomcolor';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, BarElement, RadialLinearScale, ArcElement);
var generateColor = function (length) {
    return randomColor({
        luminosity: 'light',
        format: 'rgba',
        alpha: 0.6,
        count: length
    });
};
var chartOptions = {
    responsive: true
};
var PolicyChanges = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27;
    var queryClient = useQueryClient();
    var _28 = useWidgetStore(), setModalOpen = _28.setModalOpen, setCurrentTab = _28.setCurrentTab, selectedPolicy = _28.selectedPolicy;
    var _29 = useCustomerStore(), customerId = _29.id, extCustomerID = _29.extCustomerID;
    var _30 = useConfigStore(), primaryColor = _30.style.primaryColor, platformId = _30.keys.platformId;
    var _31 = useState([]), polarChartColors = _31[0], setPolarChartColors = _31[1];
    var customerStats = useQuery(['customerStats'], function () {
        return api.customerStatistics.get({
            extCustomerID: extCustomerID,
            saasId: platformId,
            policyId: selectedPolicy.policyId,
            externalId: selectedPolicy.externalId
        });
    }, {
        enabled: customerId ? true : false,
        retry: 0
    });
    var customerQuotes = useQuery(['customerQuotes'], function () {
        return api.quote.getCustomerQuotes({
            customerID: customerId,
            policyId: selectedPolicy.policyId,
            externalId: selectedPolicy.externalId
        });
    }, {
        enabled: customerId && (selectedPolicy === null || selectedPolicy === void 0 ? void 0 : selectedPolicy.externalId) ? true : false,
        cacheTime: 0,
        retry: 0
    });
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_c = (_b = (_a = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.dataChangeComparison) &&
            !((polarChartColors === null || polarChartColors === void 0 ? void 0 : polarChartColors.length) > 0)) {
            setPolarChartColors(generateColor((_g = (_f = (_e = (_d = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.dataChangeComparison) === null || _g === void 0 ? void 0 : _g.labels.length));
        }
    }, [customerStats]);
    if (customerQuotes.isLoading || customerStats.isLoading) {
        return React.createElement(QuoteLoading, null);
    }
    return (React.createElement("div", null,
        React.createElement(PolicyStepsHeader, { pageName: "Business changes that affect your policy", handleClose: function () {
                setCurrentTab(CurrentTabEnum.POLICY);
                setModalOpen(false);
            }, hideNextButton: true }),
        React.createElement("div", { className: "w-full flex flex-col items-center justify-center gap-y-20 bg-gray-100 min-h-[90vh] pt-16 p-4" },
            React.createElement("div", { className: "bg-white p-4 rounded-lg w-full" },
                React.createElement(Timeline, { customerData: customerQuotes === null || customerQuotes === void 0 ? void 0 : customerQuotes.data })),
            React.createElement("div", { className: "grid grid-cols-3 w-full gap-6" },
                ((_b = (_a = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data) &&
                    ((_e = (_d = (_c = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.changeRateData) && (React.createElement("div", { className: "w-full h-full bg-white py-4 pr-4 rounded-lg flex flex-col items-center gap-y-3" },
                    React.createElement("span", { className: "font-semibold text-xl pl-4 self-start" }, "Change Rate"),
                    React.createElement("div", { className: "flex items-center justify-center w-full h-full" },
                        React.createElement(Line, { options: chartOptions, data: {
                                labels: (_j = (_h = (_g = (_f = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.changeRateData) === null || _j === void 0 ? void 0 : _j.labels,
                                datasets: [
                                    {
                                        fill: true,
                                        label: 'Change Rate',
                                        data: (_o = (_m = (_l = (_k = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.changeRateData) === null || _o === void 0 ? void 0 : _o.data,
                                        borderColor: primaryColor,
                                        backgroundColor: primaryColor
                                    },
                                ]
                            } })))),
                ((_q = (_p = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.data) &&
                    ((_t = (_s = (_r = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _r === void 0 ? void 0 : _r.data) === null || _s === void 0 ? void 0 : _s.data) === null || _t === void 0 ? void 0 : _t.dataChangeComparison) && (React.createElement("div", { className: "w-full h-full bg-white py-4 pr-4 rounded-lg flex flex-col items-center gap-y-3" },
                    React.createElement("span", { className: "font-semibold text-xl pl-4 self-start" }, "Changes Comparison"),
                    React.createElement("div", { className: "flex items-center justify-center w-full h-full" },
                        React.createElement(PolarArea, { data: {
                                labels: (_x = (_w = (_v = (_u = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _u === void 0 ? void 0 : _u.data) === null || _v === void 0 ? void 0 : _v.data) === null || _w === void 0 ? void 0 : _w.dataChangeComparison) === null || _x === void 0 ? void 0 : _x.labels,
                                datasets: [
                                    {
                                        label: 'changes',
                                        data: (_1 = (_0 = (_z = (_y = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _y === void 0 ? void 0 : _y.data) === null || _z === void 0 ? void 0 : _z.data) === null || _0 === void 0 ? void 0 : _0.dataChangeComparison) === null || _1 === void 0 ? void 0 : _1.data,
                                        backgroundColor: polarChartColors,
                                        borderWidth: 1
                                    },
                                ]
                            } })))),
                ((_3 = (_2 = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _2 === void 0 ? void 0 : _2.data) === null || _3 === void 0 ? void 0 : _3.data) &&
                    ((_6 = (_5 = (_4 = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _4 === void 0 ? void 0 : _4.data) === null || _5 === void 0 ? void 0 : _5.data) === null || _6 === void 0 ? void 0 : _6.mostChangesKeysData) && (React.createElement("div", { className: "w-full h-full bg-white py-4 pr-4 rounded-lg flex flex-col items-center gap-y-3" },
                    React.createElement("span", { className: "font-semibold text-xl pl-4 self-start" }, "Most Changed Keys"),
                    React.createElement("div", { className: "flex items-center justify-center w-full h-full" },
                        React.createElement(Bar, { options: __assign(__assign({}, chartOptions), { indexAxis: 'y' }), data: {
                                labels: (_10 = (_9 = (_8 = (_7 = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _7 === void 0 ? void 0 : _7.data) === null || _8 === void 0 ? void 0 : _8.data) === null || _9 === void 0 ? void 0 : _9.mostChangesKeysData) === null || _10 === void 0 ? void 0 : _10.labels,
                                datasets: [
                                    {
                                        label: 'Most Changed Keys',
                                        data: (_14 = (_13 = (_12 = (_11 = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _11 === void 0 ? void 0 : _11.data) === null || _12 === void 0 ? void 0 : _12.data) === null || _13 === void 0 ? void 0 : _13.mostChangesKeysData) === null || _14 === void 0 ? void 0 : _14.data,
                                        borderColor: primaryColor,
                                        backgroundColor: primaryColor
                                    },
                                ]
                            } })))),
                ((_16 = (_15 = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _15 === void 0 ? void 0 : _15.data) === null || _16 === void 0 ? void 0 : _16.data) &&
                    ((_19 = (_18 = (_17 = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _17 === void 0 ? void 0 : _17.data) === null || _18 === void 0 ? void 0 : _18.data) === null || _19 === void 0 ? void 0 : _19.mostChangesKeysData) && (React.createElement("div", { className: "w-full h-full bg-white py-4 pr-4 rounded-lg flex flex-col items-center gap-y-3" },
                    React.createElement("span", { className: "font-semibold text-xl pl-4 self-start" }, "Most Changed Keys"),
                    React.createElement("div", { className: "flex items-center justify-center w-full h-full" },
                        React.createElement(Radar, { data: {
                                labels: (_23 = (_22 = (_21 = (_20 = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _20 === void 0 ? void 0 : _20.data) === null || _21 === void 0 ? void 0 : _21.data) === null || _22 === void 0 ? void 0 : _22.mostChangesKeysData) === null || _23 === void 0 ? void 0 : _23.labels,
                                datasets: [
                                    {
                                        label: '# of Votes',
                                        data: (_27 = (_26 = (_25 = (_24 = customerStats === null || customerStats === void 0 ? void 0 : customerStats.data) === null || _24 === void 0 ? void 0 : _24.data) === null || _25 === void 0 ? void 0 : _25.data) === null || _26 === void 0 ? void 0 : _26.mostChangesKeysData) === null || _27 === void 0 ? void 0 : _27.data,
                                        backgroundColor: primaryColor,
                                        borderColor: primaryColor,
                                        borderWidth: 1
                                    },
                                ]
                            } }))))))));
};
export default PolicyChanges;
