import React from 'react';
var VerifiedIcon = function () {
    return (React.createElement("svg", { width: 72, height: 72, viewBox: "0 0 72 72", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("rect", { width: 72, height: 72, rx: "20.5714", className: "fill-primary" }),
        React.createElement("g", { clipPath: "url(#clip0_502_662)" },
            React.createElement("path", { d: "M36 19.5L22.5 25.5V34.5C22.5 42.825 28.26 50.61 36 52.5C43.74 50.61 49.5 42.825 49.5 34.5V25.5L36 19.5ZM33 43.5L27 37.5L29.115 35.385L33 39.255L42.885 29.37L45 31.5L33 43.5Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_502_662" },
                React.createElement("rect", { width: 36, height: 36, fill: "white", transform: "translate(18 18)" })))));
};
export default VerifiedIcon;
