var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import VerifiedIcon from '../../../icons/VerifiedIcon';
import WarningIcon from '../../../icons/WarningIcon';
import useWidgetStore from '../../../../stores/widgetStore';
import PendingIcon from '../../../icons/PendingIcon';
import useLatestQuotesWithPolicies from '../../../../hooks/useLatestQuotesWithPolicies';
import api from '../../../../api';
import QuoteLoading from '../QuoteLoading';
var QuotePending = function () {
    var _a, _b, _c, _d;
    var _e = useWidgetStore(), modalOpen = _e.modalOpen, setModalOpen = _e.setModalOpen, selectedQuote = _e.selectedQuote, setCreateQuoteSubmitted = _e.setCreateQuoteSubmitted;
    var latestQuotesWithPolicies = useLatestQuotesWithPolicies(true);
    var latestQuote = useMemo(function () {
        var latestQuote = latestQuotesWithPolicies.find(function (q) { return q.id === selectedQuote; }) || null;
        var latestPolicy = (latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.policy) || null;
        return { latestQuote: latestQuote, latestPolicy: latestPolicy };
    }, [latestQuotesWithPolicies, selectedQuote]).latestQuote;
    var isLimit = (latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.product.Underwriter.widgetFlow) === 'limit';
    var applicationCreated = Boolean((_a = latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.partnerData) === null || _a === void 0 ? void 0 : _a.shareableApplication);
    var isSubmitting = Boolean((_b = latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.partnerData) === null || _b === void 0 ? void 0 : _b.submitting);
    var isSubmitted = Boolean((_d = (_c = latestQuote === null || latestQuote === void 0 ? void 0 : latestQuote.partnerData) === null || _c === void 0 ? void 0 : _c.shareableApplication) === null || _d === void 0 ? void 0 : _d.submitted_at);
    useEffect(function () {
        setCreateQuoteSubmitted(false);
    }, []);
    var _f = useQuery({
        queryKey: ['quote', 'limit', 'application-policies'],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, api.quote.getLimitApplicationPolicies({
                            quoteId: latestQuote.id
                        })];
                    case 1:
                        data = _c.sent();
                        return [2 /*return*/, (((_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.results) === null || _b === void 0 ? void 0 : _b.filter(function (quote) {
                                var keeping = [
                                    'not_sent',
                                    'waiting_on_market',
                                    'clearance_review',
                                    'quote',
                                    'indication',
                                    'expired_quote',
                                ].includes(quote.status);
                                return keeping;
                            })) || [])];
                }
            });
        }); },
        refetchInterval: 30000,
        enabled: isSubmitted
    }), policies = _f.data, isLoading = _f.isLoading;
    var hasPolicies = policies && policies.length > 0;
    if (isSubmitted && isLoading) {
        return React.createElement(QuoteLoading, null);
    }
    return (React.createElement("div", null, modalOpen ? (React.createElement("div", { className: "flex flex-col items-center justify-start sm:px-4" },
        React.createElement("div", { className: "flex flex-col items-center justify-center" },
            React.createElement(PendingIcon, null),
            isLimit ? (React.createElement(React.Fragment, null, applicationCreated ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" }, "We are preparing your Quotes"),
                React.createElement("span", { className: "text-[16px] mt-3" }, "This may take some time. Please check back regularly"))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" }, "We are preparing your Application form"),
                React.createElement("span", { className: "text-[16px] mt-3" }, "This will just take a few seconds or you can exit and complete the application later"))))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" }, "Your policy will be issued in a few days."),
                React.createElement("span", { className: "text-[16px] mt-3" }, "We are still reviewing some legal issues that may take us a few days to resolve"))),
            React.createElement("button", { className: "px-3 py-1.5 text-sm text-white bg-[#FD515E] font-bold border-2 border-[#FD515E] rounded-lg mt-6", onClick: function () { return setModalOpen(false); } }, isLimit && !applicationCreated
                ? 'Exit and Complete Later'
                : 'Understood')))) : (React.createElement("div", { className: "rounded-3xl mx-2 px-12 bg-backgroundColor my-2 flex flex-col h-full justify-center text-textColor" },
        React.createElement("div", { className: "flex flex-col items-center justify-center" }, isLimit && applicationCreated && !isSubmitted && !isSubmitting ? (React.createElement(React.Fragment, null,
            React.createElement(WarningIcon, null),
            React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-4 text-[#1F2751]" }, "We need you to answer a few more questions."),
            React.createElement("span", { className: "text-[16px] mt-2" }, "You're just a few steps away from securing your insurance policy."),
            React.createElement("div", { className: "mt-5" },
                React.createElement("button", { className: "h-12 px-10 border border-primary text-primary font-bold rounded-2xl", onClick: function () { return setModalOpen(true); } }, "Continue Form")))) : (React.createElement(React.Fragment, null,
            React.createElement(VerifiedIcon, null),
            isLimit && hasPolicies ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751] max-w-[280px]" }, "We have some quotes ready for you"),
                React.createElement("button", { className: "px-6 py-3 text-md text-primary font-bold border border-primary bg-white rounded-lg mt-6", onClick: function () { return setModalOpen(true); } }, "Review My Quotes"))) : (React.createElement(React.Fragment, null,
                isLimit ? (React.createElement(React.Fragment, null, applicationCreated ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" }, "We are preparing the best Quotes for your business"),
                    React.createElement("span", { className: "text-[16px] mt-3" }, "This may take some time. Please check back regularly. We will display a notice when we have some quotes for your review."))) : (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" }, "We are preparing your Application form"),
                    React.createElement("span", { className: "text-[16px] mt-3" }, "This will just take a few seconds or you can exit and complete the application later"))))) : (React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" },
                    "Your ",
                    isLimit ? 'quote' : 'policy',
                    " is being reviewed")),
                React.createElement("div", { className: "flex items-center mt-3 gap-x-4 text-[#202851]" },
                    React.createElement("span", { className: "w-2 h-2 bg-[#F3A449] rounded-full" }),
                    React.createElement("span", null, "In Progress")))))))))));
};
export default QuotePending;
