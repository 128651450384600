import React, { useState } from 'react';
import useConfigStore from '../../../stores/configStore';
import AccorddionCloseIcon from '../../icons/AccordionCloseIcon';
import AccordionOpenIcon from '../../icons/AccordionOpenIcon';
var Accordion = function (_a) {
    var emoji = _a.emoji, heading = _a.heading, description = _a.description, coverageSum = _a.coverageSum, coverageDeductible = _a.coverageDeductible, coverage = _a.coverage, defaultOpen = _a.defaultOpen;
    var style = useConfigStore().style;
    var primaryColor = style.primaryColor;
    var _b = useState(Boolean(defaultOpen)), isAccordionOpen = _b[0], setIsAccordionOpen = _b[1];
    return (React.createElement("div", { className: "w-full" },
        React.createElement("div", { className: "w-full flex flex-col" },
            React.createElement("div", { className: "flex items-stretch gap-x-1" },
                React.createElement("button", { type: "button", className: "flex items-center bg-[#F8FAFE] py-3.5 px-4 w-full sm:rounded-l-xl", onClick: function () { return setIsAccordionOpen(!isAccordionOpen); } },
                    isAccordionOpen ? React.createElement(AccorddionCloseIcon, null) : React.createElement(AccordionOpenIcon, null),
                    React.createElement("div", { className: "ml-4 text-2xl" }, emoji),
                    React.createElement("span", { className: "text-sm font-semibold text-[#1F2751] ml-2.5 sm:text-lg" }, heading)),
                React.createElement("div", { className: "py-3.5 px-1 bg-[#F8FAFE] rounded w-[28%] hidden sm:flex sm:items-center sm:justify-center" }, coverageSum),
                React.createElement("div", { className: "py-3.5 px-1 bg-[#F8FAFE] text-center rounded-r-xl rounded-l w-[28%] hidden sm:flex sm:items-center sm:justify-center" }, coverageDeductible)),
            isAccordionOpen && (React.createElement("div", { className: "flex flex-col divide-y-[1px] divide-[#E9EDF7B2]" },
                (coverage === null || coverage === void 0 ? void 0 : coverage.length) > 0 &&
                    (coverage === null || coverage === void 0 ? void 0 : coverage.map(function (listItem, index) { return (React.createElement("div", { className: "flex items-stretch justify-between gap-x-1 py-3 px-3 sm:px-0 sm:justify-start" },
                        React.createElement("span", { className: "text-left text-sm text-[#1F2751] w-full sm:text-lg sm:pl-[4.3rem]" }, listItem === null || listItem === void 0 ? void 0 : listItem.coverageText),
                        (listItem === null || listItem === void 0 ? void 0 : listItem.coverageSum) && (React.createElement("div", { className: "px-1 rounded w-[28%] text-sm flex items-center justify-center sm:text-[16px]" }, listItem === null || listItem === void 0 ? void 0 : listItem.coverageSum)),
                        React.createElement("div", { className: "px-1 rounded-r-xl rounded-l w-[28%] hidden sm:flex sm:items-center sm:justify-center" }, listItem === null || listItem === void 0 ? void 0 : listItem.coverageDeductible))); })),
                React.createElement("div", { className: "rounded-r-xl rounded-l w-full flex items-stretch justify-between gap-x-1 py-3 px-3 sm:hidden" },
                    React.createElement("span", { className: "text-left text-sm text-[#1F2751] w-full" }, "Deductible"),
                    React.createElement("span", { className: "px-1 w-[40%] text-sm flex items-center justify-center" }, coverageDeductible)))))));
};
export default Accordion;
