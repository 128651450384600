import { instance } from './index';
var QUOTE_URL = '/widget/quote';
var create = function (_a) {
    var customerID = _a.customerID, saasId = _a.saasId, items = _a.items, productId = _a.productId, externalID = _a.externalID, quoteExternalId = _a.quoteExternalId;
    return instance({
        method: 'POST',
        url: "".concat(QUOTE_URL, "?externalId=").concat(externalID),
        data: {
            customerId: customerID,
            saasId: saasId,
            externalId: quoteExternalId,
            productId: productId,
            items: items
        }
    });
};
// add this in new lambda
var createNotification = function (_a) {
    var customerID = _a.customerID, saasId = _a.saasId, items = _a.items, productId = _a.productId, externalID = _a.externalID, quoteExternalId = _a.quoteExternalId, policyId = _a.policyId;
    return instance({
        method: 'POST',
        url: "".concat(QUOTE_URL, "/notification?externalId=").concat(externalID),
        data: {
            customerId: customerID,
            saasId: saasId,
            externalId: quoteExternalId,
            policyId: policyId,
            productId: productId,
            items: items
        }
    });
};
// add this in new lambda
var getCustomerQuotes = function (_a) {
    var customerID = _a.customerID, policyId = _a.policyId, externalId = _a.externalId;
    var url = "".concat(QUOTE_URL, "/customer-quotes/").concat(customerID, "?externalId=").concat(externalId);
    if (policyId) {
        url += "&policyId=".concat(policyId);
    }
    return instance({
        method: 'GET',
        url: url
    });
};
var update = function (_a) {
    var quoteID = _a.quoteID, customerID = _a.customerID, saasId = _a.saasId, items = _a.items, productId = _a.productId, externalID = _a.externalID;
    return instance({
        method: 'PUT',
        url: "".concat(QUOTE_URL, "/").concat(quoteID, "?externalId=").concat(externalID),
        data: {
            customerId: customerID,
            saasId: saasId,
            productId: productId,
            items: items
        }
    });
};
var submitLimitApplication = function (_a) {
    var quoteId = _a.quoteId, answers = _a.answers;
    return instance({
        method: 'POST',
        url: "".concat(QUOTE_URL, "/").concat(quoteId, "/submit-limit-application"),
        data: {
            answers: answers
        }
    });
};
var getLimitApplicationPolicies = function (_a) {
    var quoteId = _a.quoteId;
    return instance({
        method: 'GET',
        url: "".concat(QUOTE_URL, "/").concat(quoteId, "/limit-application-policies")
    });
};
var bindLimitApplicationPolicy = function (_a) {
    var quoteId = _a.quoteId, limitPolicyId = _a.limitPolicyId;
    return instance({
        method: 'POST',
        url: "".concat(QUOTE_URL, "/").concat(quoteId, "/bind"),
        data: {
            limitPolicyId: limitPolicyId
        }
    });
};
// add this in new lambda
var listLimitPolicyDocuments = function (_a) {
    var quoteId = _a.quoteId, limitPolicyId = _a.limitPolicyId;
    return instance({
        method: 'GET',
        url: "".concat(QUOTE_URL, "/").concat(quoteId, "/documents?limitPolicyId=").concat(limitPolicyId)
    });
};
var uploadLimitPolicyDocument = function (_a) {
    var quoteId = _a.quoteId, limitPolicyId = _a.limitPolicyId, docusealId = _a.docusealId, documentName = _a.documentName;
    var url = "".concat(QUOTE_URL, "/").concat(quoteId, "/documents?limitPolicyId=").concat(limitPolicyId);
    var data = {
        limitPolicyId: limitPolicyId,
        docusealId: docusealId,
        documentName: documentName
    };
    return instance({
        method: 'POST',
        url: url,
        data: data
    });
};
export default {
    create: create,
    update: update,
    createNotification: createNotification,
    getCustomerQuotes: getCustomerQuotes,
    submitLimitApplication: submitLimitApplication,
    getLimitApplicationPolicies: getLimitApplicationPolicies,
    bindLimitApplicationPolicy: bindLimitApplicationPolicy,
    listLimitPolicyDocuments: listLimitPolicyDocuments,
    uploadLimitPolicyDocument: uploadLimitPolicyDocument
};
