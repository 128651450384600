import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import getLatestPolicyOrQuote from '../utils/getLatestPolicyOrQuote';
export default function useLatestPolicy() {
    var _a, _b;
    var queryClient = useQueryClient();
    var customer = queryClient.getQueryData(['customer']);
    var _c = useState(null), latestPolicy = _c[0], setLatestPolicy = _c[1];
    useEffect(function () {
        var _a, _b;
        var policies = (_b = (_a = customer === null || customer === void 0 ? void 0 : customer.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.Policies;
        if (!policies || !((policies === null || policies === void 0 ? void 0 : policies.length) > 0))
            return;
        var latestPolicyID = getLatestPolicyOrQuote(policies);
        setLatestPolicy(policies[latestPolicyID]);
        // @ts-ignore
    }, [(_b = (_a = customer === null || customer === void 0 ? void 0 : customer.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.Policies]);
    return latestPolicy;
}
