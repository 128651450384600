import React from 'react';
import VerifiedIcon from '../../../icons/VerifiedIcon';
var StartRequest = function (_a) {
    var theme = _a.theme, style = _a.style, buttonLabel = _a.buttonLabel, isButtonDisabled = _a.isButtonDisabled, handleClick = _a.handleClick;
    return (React.createElement("div", { className: "rounded-3xl mx-2 mt-30 px-12 bg-backgroundColor my-2 flex flex-col h-full justify-center text-textColor" },
        React.createElement("div", { className: "flex flex-col items-center justify-center" },
            React.createElement(VerifiedIcon, null),
            React.createElement("span", { className: "text-2xl font-bold text-center w-full mt-5 text-[#1F2751]" }, "Buy your policy now")),
        React.createElement("button", { className: "rounded-2xl bg-primary ".concat(theme == 'night' ? 'rippleNight' : 'ripple', " text-primary font-bold mt-6 mx-auto tracking-lighter px-8 py-4 w-full"), style: style, onClick: handleClick, disabled: isButtonDisabled }, buttonLabel)));
};
export default StartRequest;
