import React from 'react';
import shade from '../../../../utils/shade';
import BackButton from '../../../common/BackButton';
export default function ViewClaim(_a) {
    var activeClaim = _a.activeClaim, setActiveClaim = _a.setActiveClaim, setEditMode = _a.setEditMode, primaryColor = _a.primaryColor;
    var getClaimTotal = function (formatted) {
        var _a;
        var sum = 0;
        (_a = activeClaim.item) === null || _a === void 0 ? void 0 : _a.map(function (ci) {
            var itemAmount = Number(ci === null || ci === void 0 ? void 0 : ci.damageAmount) > 0 ? Number(ci === null || ci === void 0 ? void 0 : ci.damageAmount) : 0;
            sum += itemAmount;
        });
        return formatted ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(sum) : sum;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "m-3 flex flex-col justify-between px-4" },
            React.createElement("div", { className: "grid grid-cols-3 mb-4" },
                React.createElement(BackButton, { onClick: function () { return setActiveClaim(null); } }),
                React.createElement("h1", { className: "text-lg font-bold text-black self-center text-center" },
                    "Claim #", activeClaim === null || activeClaim === void 0 ? void 0 :
                    activeClaim.claimId)),
            React.createElement("ul", { className: "grid grid-cols-2 grid-rows 4 gap-4 text-lg text-gray-500 self-start text-left py-2 px-4" },
                React.createElement("li", { className: "text-primary font-bold" }, "Policy #:"),
                React.createElement("li", null, activeClaim.policyId),
                React.createElement("li", { className: "text-primary font-bold" }, "Status:"),
                React.createElement("li", null, activeClaim.status),
                React.createElement("li", { className: "text-primary font-bold" }, "Title:"),
                React.createElement("li", null, activeClaim.title),
                React.createElement("li", { className: "text-primary font-bold" }, "Description of Loss / Damage:"),
                React.createElement("li", null, activeClaim.impact),
                React.createElement("li", { className: "text-primary font-bold" }, "Total Claimed:"),
                React.createElement("li", null, getClaimTotal(true))),
            React.createElement("button", { className: "rounded-2xl bg-primary ripple text-primary w-full font-bold mt-2 mx-auto tracking-lighter px-8 py-4", style: { backgroundColor: shade(primaryColor, 0.9) }, onClick: function () { return setEditMode(true); } }, "Update Claim"))));
}
