import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import shade from '../utils/shade';
import { BounceLoader } from 'react-spinners';
import useConfigStore from '../stores/configStore';
import useWidgetStore, { CurrentTabEnum } from '../stores/widgetStore';
import getStyles from '../utils/getStyles';

const Tab = ({ name, number, icon, disabled, isLeftAligned }) => {
  const { style } = useConfigStore();
  const { primaryColor, theme } = style;
  const { currentTab, setCurrentTab, showNotification } = useWidgetStore();

  const loading = false;

  const active = useMemo(() => currentTab === number, [currentTab, number]);

  const color = active ? 'primary' : 'gray-400';
  const cursor = active
    ? 'cursor-default'
    : disabled
    ? 'cursor-not-allowed'
    : 'cursor-pointer';
  const iconColor = showNotification
    ? active
      ? primaryColor
      : shade(primaryColor, 0.7)
    : null;

  const updateTab = (e, number) => {
    e.preventDefault();
    setCurrentTab(number);
  };

  return (
    <li
      className="last:mr-0 text-center w-full bg-backgroundColor"
      style={{
        flex:
          number === CurrentTabEnum.NOTIFICATIONS
            ? CurrentTabEnum.POLICY
            : CurrentTabEnum.CLAIMS,
      }}
    >
      <a
        className={`text-xl capitalize font-semibold py-4 block leading-normal ${
          !isLeftAligned && (theme == 'night' ? 'rippleNight' : 'ripple')
        } flex flex-col justify-center ${
          isLeftAligned ? 'items-start pl-6 text-[#1F2751]' : 'items-center'
        } text-${color} ${cursor}`}
        onClick={(e) => !disabled && updateTab(e, number)}
        style={{
          backgroundColor: active
            ? getStyles(theme)?.backgroundColor
            : getStyles(theme)?.tabNonActive, //'#F8F8F9', //111315
          height: '59px',
        }}
      >
        {loading ? (
          <BounceLoader
            color={number === currentTab ? primaryColor : '#9ca3af'}
            size={20}
          />
        ) : icon ? (
          showNotification ? (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              size="lg"
              color={iconColor}
            />
          ) : (
            <FontAwesomeIcon icon={icon} size="lg" />
          )
        ) : (
          <></>
        )}

        {name}
      </a>
    </li>
  );
};

export default Tab;
