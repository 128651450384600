export var requiredDependency = {
    physical_address: {
        required_if: {
            physical_address_same_as_mailing: false
        },
        overwrite: {
            required: false
        }
    },
    pci_compliance_with_na: {
        required_if: {
            accepts_credit_cards: true
        }
    },
    pci_compliant_payments: {
        required_if: {
            accepts_credit_cards: true,
            pci_compliance_with_na: 'No'
        }
    },
    has_offline_or_cloud_backups: {
        required_if: {
            has_backups: true
        }
    },
    critical_info_backup_cadence: {
        required_if: {
            has_backups: true
        }
    },
    critical_system_rto: {
        required_if: {
            has_backups: true
        }
    },
    email_security_software: {
        required_if: {
            has_email_filtering: true
        }
    },
    mfa_for_remote_access: {
        required_if: {
            allows_remote_access: true
        }
    },
    mfa_provider: {
        required_if: {
            mfa_for_remote_access: true
        }
    },
    backup_protected_by_mfa: {
        required_if: {
            has_backups: true
        }
    },
    anti_virus_provider: {
        required_if: {
            endpoint_protection_for_all_devices: true
        }
    },
    edr_provider: {
        required_if: {
            endpoint_protection_for_all_devices: true
        }
    },
    claims_legal_or_regulatory_actions_most_recent: {
        required_if: {
            claims_legal_or_regulatory_actions_past_5_years: true
        }
    },
    claims_legal_or_regulatory_actions_past_5_years_amount: {
        required_if: {
            claims_legal_or_regulatory_actions_past_5_years: true
        }
    }
};
export var limitKeyLabelMapper = {
    'industry.naics_code_2017': 'NAICS Code 2017',
    'industry.sic_code': 'SIC Code',
    'industry.title': 'Industry Title'
};
export var limitFallbackValues = {
    'company_gross_revenue.currency': 'USD',
    'gross_revenue_next_12_months.currency': 'USD'
};
export var currencyValueMapper = {
    'company_gross_revenue.amount': function (value) { return value * 100; },
    'gross_revenue_next_12_months.amount': function (value) { return value * 100; }
};
export var limitGroupQuestionOrder = {
    industry: 0,
    entity_name: 1,
    ownership_type: 2,
    mailing_address: 3,
    primary_contact: 4,
    primary_website: 5,
    total_employees: 6,
    year_established: 7,
    business_activities: 8,
    company_gross_revenue: 9,
    gross_revenue_next_12_months: 10,
    primary_contact_phone_number: 11,
    physical_address_same_as_mailing: 12,
    physical_address: 13,
    common_cyber_disallowed_industries_with_explanation: 14
};
