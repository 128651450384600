import { compareAsc } from 'date-fns';
var getLatestPolicyOrQuote = function (data, getWithoutFilter) {
    if (getWithoutFilter === void 0) { getWithoutFilter = false; }
    var latestIndex = 0;
    var quotes = getWithoutFilter
        ? data
        : data === null || data === void 0 ? void 0 : data.filter(function (v) {
            return v.status == 'complete' || v.status == 'bound' || v.status == 'active';
        });
    quotes === null || quotes === void 0 ? void 0 : quotes.forEach(function (v, i) {
        var _a;
        var itemDate = new Date(v === null || v === void 0 ? void 0 : v.createdAt);
        var latestDate = new Date((_a = data[latestIndex]) === null || _a === void 0 ? void 0 : _a.createdAt);
        var result = compareAsc(itemDate, latestDate);
        if (result === 1)
            latestIndex = i;
    });
    return latestIndex;
};
export default getLatestPolicyOrQuote;
