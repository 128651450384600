import React from 'react';
import Accordion from './Accordion';
import useWidgetStore, { CurrentTabEnum } from '../../../stores/widgetStore';
import useLatestPolicy from '../../../hooks/useLatestPolicy';
import PolicyStepsHeader from '../../common/PolicyStepsHeader';
var Coverages = function (_a) {
    var _b = _a.showHeader, showHeader = _b === void 0 ? false : _b;
    var _c = useWidgetStore(), setCurrentTab = _c.setCurrentTab, setModalOpen = _c.setModalOpen, modalOpen = _c.modalOpen;
    var latestPolicy = useLatestPolicy();
    return (React.createElement("div", { className: "flex flex-col items-center sm:px-4" },
        modalOpen && showHeader && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "w-full" },
                React.createElement(PolicyStepsHeader, { pageName: "Your Policy", hideNextButton: true })),
            React.createElement("div", { className: "w-full rounded-2xl bg-[#F8FAFE] p-1 flex items-stretch my-6" },
                React.createElement("button", { className: "text-[16px] text-[#A5A9B9] font-medium py-3 rounded-2xl w-full", onClick: function (e) { return setCurrentTab(CurrentTabEnum.POLICY_INFO); } }, "Details"),
                React.createElement("button", { className: "text-[16px] text-primary bg-white font-medium py-3 rounded-2xl w-full" }, "Coverages")))),
        React.createElement("div", { className: "flex flex-col items-center w-full sm:gap-y-3" },
            React.createElement("div", { className: "flex items-stretch gap-x-1 py-3 w-full" },
                React.createElement("span", { className: "text-left text-lg font-semibold text-[#1F2751] w-full flex items-center ml-2 sm:ml-0" }, "What your policy covers"),
                React.createElement("div", { className: "font-semibold py-3.5 px-1 bg-[#F8FAFE] rounded-l-xl rounded-r w-[28%] hidden sm:flex sm:items-center sm:justify-center" }, "Coverage"),
                React.createElement("div", { className: "font-semibold py-3.5 px-1 bg-[#F8FAFE] text-center rounded-r-xl rounded-l w-[28%] hidden sm:flex sm:items-center sm:justify-center" }, "Deductible")),
            React.createElement("div", { className: "flex flex-col items-center divide-y-2 w-full mt-3 sm:gap-y-3 sm:divide-none" },
                React.createElement(Accordion, { emoji: "\uD83C\uDFE2", heading: "Property Insurance", coverageSum: "Up to $500,000", coverageDeductible: "$1,000 per occurrence", coverage: [
                        {
                            coverageText: 'Buildings and Structures',
                            coverageSum: 'Up to $500,000'
                        },
                        {
                            coverageText: 'Business Personal Property',
                            coverageSum: 'Up to $250,000'
                        },
                        {
                            coverageText: 'Business Interruption: Actual Loss Sustained for 12 months'
                        },
                    ], defaultOpen: true }),
                React.createElement(Accordion, { emoji: "\uD83E\uDD1D", heading: "General Liability Insurance", coverageSum: "Up to $500,000", coverageDeductible: "$2,500 per claim", coverage: [
                        {
                            coverageText: 'Bodily Injury and Property Damage',
                            coverageSum: '$1,000,000 per occurrence / $2,000,000 aggregate'
                        },
                        {
                            coverageText: 'Products-Completed Operations',
                            coverageSum: '$1,000,000 per occurrence / $2,000,000 aggregate'
                        },
                        {
                            coverageText: 'Personal and Advertising Injury',
                            coverageSum: '$500,000 per occurrence / $1,000,000 aggregate'
                        },
                    ] }),
                React.createElement(Accordion, { emoji: "\uD83D\uDEE0\uFE0F", heading: "Workers' Compensation", coverageSum: "Up to $500,000", coverageDeductible: "As required by state law", coverage: [
                        {
                            coverageText: 'Statutory limits as required by state law'
                        },
                        {
                            coverageText: "Employer's Liability",
                            coverageSum: '$500,000 each accident / $1,000,000 disease policy limit / $100,000 disease each employee'
                        },
                    ] }),
                React.createElement(Accordion, { emoji: "\uD83D\uDE97", heading: "Commercial Auto Insurance", coverageSum: "Up to $250,000 per accident", coverageDeductible: "$500 per accident", coverage: [
                        {
                            coverageText: 'Owned Vehicles',
                            coverageSum: ' Up to $250,000 per accident'
                        },
                        {
                            coverageText: 'Non-Owned Vehicles',
                            coverageSum: 'Up to $100,000 per accident'
                        },
                    ] }),
                React.createElement(Accordion, { emoji: "\u2602\uFE0F", heading: "Umbrella Liability Insurance", coverageSum: "Up to $1,000,000", coverageDeductible: "$5,000 per occurrence", coverage: [
                        {
                            coverageText: 'Excess Liability Coverage'
                        },
                    ] }),
                React.createElement(Accordion, { emoji: "\uD83D\uDD10", heading: "Cyber Liability Insurance", coverageSum: "Up to $100,000", coverageDeductible: "$2,500 per event", coverage: [
                        {
                            coverageText: 'Data Breach Response',
                            coverageSum: 'Up to $100,000'
                        },
                        {
                            coverageText: 'Cyber Extortion',
                            coverageSum: 'Up to $50,000'
                        },
                        {
                            coverageText: 'Data Restoration',
                            coverageSum: 'Up to $75,000'
                        },
                    ] }),
                React.createElement(Accordion, { emoji: "\uD83D\uDD75\uFE0F\u200D\u2642\uFE0F", heading: "Employee Dishonesty", coverageSum: "Up to $50,000", coverageDeductible: "$1,000 per occurrence", coverage: [
                        {
                            coverageText: 'Employee Theft and Fraud',
                            coverageSum: 'Up to $50,000'
                        },
                    ] })))));
};
export default Coverages;
