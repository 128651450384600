import React from 'react';
import { RotateLoader } from 'react-spinners';

export default function CardLoader({ color, text = 'Loading...', size = 15, margin = 2 }) {
  return (
    <div className="absolute inset-y-1/3 w-full">
      <RotateLoader color={color} loading={true} size={size} margin={margin} />
      {text !== false && <p className="uppercase italic mt-8">{text}</p>}
    </div>
  );
}
