import React from 'react';
import shade from '../../utils/shade';
import useConfigStore from '../../stores/configStore';
import getStyles from '../../utils/getStyles';
import QuoteInfo from '../Tabs/QuoteInfo/QuoteInfo';
import UserIcon from '../icons/UserIcon';
import MoneyMinusIcon from '../icons/MoneyMinusIcon';
import MoneyTagIcon from '../icons/MoneyTagIcon';
import MoneyWalletIcon from '../icons/MoneyWalletIcon';
import Coverages from '../Tabs/Coverages/Coverages';

export default function PolicyPurchaseConfirmation({
  quoteId,
  insuranceProvider = 'WTW',
  quoteExpense,
  quoteIPT,
  quoteTotalAmount,
}) {
  const { style } = useConfigStore();
  const { primaryColor, theme } = style;

  return (
    <div
      className={`rounded-3xl mt-30 pb-8 pt-4 bg-backgroundColor text-textColor my-2 flex flex-col h-full items-center justify-start sm:mx-2 sm:px-1`}
    >
      <div className="flex flex-col items-center justify-start w-full px-3 sm:mt-6 sm:px-0">
        <span className="text-3xl font-bold text-center w-full mb-4">
          Your Insurance Quote
        </span>
        <div className="flex items-center text-lg divide-x-2">
          <span className="px-2">{quoteId}</span>
          <div className="flex items-center gap-x-2 px-2">
            <UserIcon />
            <span className="capitalize">{insuranceProvider}</span>
          </div>
        </div>

        <div
          className="w-full rounded-2xl grid grid-cols-2 grid-rows-2 border-2 text-[#1F2751] mt-6 sm:flex sm:items-stretch sm:justify-between"
          style={{
            backgroundColor:
              theme == 'night'
                ? shade(primaryColor, -0.7)
                : shade(primaryColor, 0.9),
            borderColor:
              theme == 'night'
                ? shade(primaryColor, -0.6)
                : shade(primaryColor, 0.8),
          }}
        >
          <div className="w-full flex items-center gap-x-6 pl-7 py-3">
            <MoneyMinusIcon />
            <div className="flex flex-col items-start text-[16px] sm:text-lg">
              <span className="font-bold">&#163;{quoteExpense}</span>
              <span className="text-xs sm:text-sm">Yearly Premium</span>
            </div>
          </div>

          <div className="w-full flex items-center gap-x-6 pl-7 py-3">
            <div
              className="w-[2px] h-full"
              style={{
                backgroundColor:
                  theme == 'night'
                    ? shade(primaryColor, -0.6)
                    : shade(primaryColor, 0.8),
              }}
            />
            <MoneyTagIcon />
            <div className="flex flex-col items-start text-[16px] sm:text-lg">
              <span className="font-bold">&#163;{quoteIPT}</span>
              <span className="text-xs sm:text-sm">IPT</span>
            </div>
          </div>

          <div className="w-full flex items-center justify-center gap-x-6 py-3 bg-primary rounded-b-2xl text-white col-span-2 sm:rounded-2xl sm:justify-start sm:pl-7">
            <MoneyWalletIcon />
            <div className="flex flex-col items-start text-[16px] sm:text-lg">
              <span className="font-bold">&#163;{quoteTotalAmount}</span>
              <span className="text-xs sm:text-sm">Total Payable Amount</span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-16">
        <Coverages showHeader={false} />
      </div>
    </div>
  );
}
