import { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import getLatestPolicyOrQuote from '../utils/getLatestPolicyOrQuote';
export default function useLatestQuote() {
    var _a, _b;
    var queryClient = useQueryClient();
    var customer = queryClient.getQueryData(['customer']);
    var _c = useState(null), latestQuote = _c[0], setLatestQuote = _c[1];
    useEffect(function () {
        var _a, _b;
        var quotes = (_b = (_a = customer === null || customer === void 0 ? void 0 : customer.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.Quotes;
        if (!quotes || !((quotes === null || quotes === void 0 ? void 0 : quotes.length) > 0))
            return;
        var latestQuoteID = getLatestPolicyOrQuote(quotes);
        setLatestQuote(quotes[latestQuoteID]);
    }, [(_b = (_a = customer === null || customer === void 0 ? void 0 : customer.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.Quotes]);
    return latestQuote;
}
