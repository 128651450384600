import React, { useState } from 'react';

import PolicyNotice from './PolicyNotice';
import useProductStore from '../stores/productStore';
import useCustomerStore from '../stores/customerStore';

const Footer = () => {
  const [policyNotice, setPolicyNotice] = useState();
  const { productBranding } = useProductStore();

  const openPolicyNotice = () => {
    setPolicyNotice(true);
  };

  const closePolicyNotice = () => {
    setPolicyNotice(false);
  };

  const hasNotice = Boolean(productBranding?.policyNotice);

  return (
    <>
      <div
        className={`flex flex-col md:flex-row md:items-center py-3 gap-1 ${
          hasNotice ? 'justify-between' : 'justify-center'
        } px-6`}
      >
        <div className="flex gap-2">
          {productBranding ? (
            <>
              <span className="text-gray-600 text-sm italic">Powered by</span>
              <div className="flex items-center gap-x-1">
                {productBranding?.icon && (
                  <img
                    src={productBranding?.icon}
                    alt="logo"
                    className="max-w-[2rem]"
                  />
                )}
                {productBranding?.name && (
                  <span className="text-primary text-sm capitalize font-extrabold italic">
                    {productBranding?.name}
                  </span>
                )}
              </div>
            </>
          ) : null}
        </div>
        {hasNotice && (
          <div className="flex items-center">
            <a
              href="#"
              className="text-md text-primary font-semibold"
              onClick={openPolicyNotice}
            >
              Privacy Notice
            </a>
          </div>
        )}
      </div>
      {policyNotice && (
        <PolicyNotice
          onClose={closePolicyNotice}
          policyNotice={productBranding?.policyNotice}
        />
      )}
    </>
  );
};

export default Footer;
