var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DocusealBuilder, DocusealForm } from '@docuseal/react';
import SigningTutorial from './SigningTutorial';
import api from '../../../api';
import useCustomerStore from '../../../stores/customerStore';
import formBuilderGif from '../../../assets/form-builder.gif';
import formSigningGif from '../../../assets/form-signing.gif';
import { generateUniqueEmail } from '../../../utils/generateUniqueEmail';
var slides = [
    {
        isStart: true
    },
    {
        progress: '1 / 2',
        guideHeading: 'Prepare the Document',
        guideText: 'Drag and drop your signature and date into the required fields. Then click “Save” to proceed to the document signing section.',
        guideImageUrl: formBuilderGif
    },
    {
        progress: '2 / 2',
        guideHeading: 'Sign and Download',
        guideText: 'Add your signature and select the date. Once complete, you’ll be able to download your document.',
        guideImageUrl: formSigningGif
    },
];
var LimitPolicyPayment = function (_a) {
    var onClose = _a.onClose, documentId = _a.documentId, documentLink = _a.documentLink, quoteId = _a.quoteId, limitPolicyId = _a.limitPolicyId, templateId = _a.templateId, documentName = _a.documentName;
    var queryClient = useQueryClient();
    var _b = useState(0), tutorialSlide = _b[0], setTutorialSide = _b[1];
    var _c = useState(templateId ? false : true), loading = _c[0], setLoading = _c[1];
    var _d = useState(templateId), onPreview = _d[0], setOnPreview = _d[1];
    var customerStore = useCustomerStore();
    var extCustomerEmail = customerStore.extCustomerEmail, extCustomerID = customerStore.extCustomerID, extCustomerName = customerStore.extCustomerName;
    var uniqueEmail = generateUniqueEmail(extCustomerEmail, documentId);
    var docusealToken = useQuery(['auth', 'docusealToken', documentName], {
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.auth.getDocusealToken({
                            email: extCustomerEmail,
                            externalId: "".concat(extCustomerID, "-").concat(documentName),
                            name: documentName,
                            documentUrl: documentLink
                        })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data.docusealToken];
                }
            });
        }); },
        enabled: Boolean(extCustomerEmail &&
            extCustomerID &&
            extCustomerName &&
            documentLink &&
            !onPreview),
        refetchOnWindowFocus: false
    }).data;
    var uploadDocument = useMutation(api.quote.uploadLimitPolicyDocument, {
        onSuccess: function () {
            onClose();
            queryClient.invalidateQueries(['customer']);
            queryClient.invalidateQueries(['quote', 'limit', 'policy-documents']);
        }
    });
    var activeSlide = slides[tutorialSlide];
    var nextSlideHandler = function () {
        setTutorialSide(function (v) { return v + 1; });
    };
    var prevSlideHandler = function () {
        setTutorialSide(function (v) { return (v === 0 ? 0 : v - 1); });
    };
    var endSlideHandler = function () {
        setTutorialSide(slides.length);
    };
    var content;
    if (uploadDocument.isLoading) {
        content = (React.createElement("div", { className: "h-full w-full flex items-center flex-col justify-center" },
            React.createElement("div", { className: "mb-4 loader" }),
            React.createElement("p", null, "Uploading Document")));
    }
    else if (activeSlide) {
        content = (React.createElement(SigningTutorial, { guideText: activeSlide.guideText, guideImageUrl: activeSlide.guideImageUrl, progress: activeSlide.progress, guideHeading: activeSlide.guideHeading, isStart: activeSlide.isStart, onPrev: prevSlideHandler, onNext: nextSlideHandler, onEnd: endSlideHandler, isLast: tutorialSlide === slides.length - 1 }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "fixed z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[95vw] bg-backgroundColor rounded-[16px] border border-slate-200 max-h-[95vh] h-[95vh] overflow-y-auto" },
            React.createElement("div", { className: "px-8 py-6 h-full min-h-full" }, content ? (content) : (React.createElement(React.Fragment, null, !loading && onPreview ? (React.createElement(DocusealForm, { src: "https://docuseal.co/d/".concat(onPreview), email: uniqueEmail, onComplete: function (data) {
                    uploadDocument.mutate({
                        quoteId: quoteId,
                        limitPolicyId: limitPolicyId,
                        docusealId: "".concat(data.submission.id),
                        documentName: documentName,
                        documentId: documentId
                    });
                } })) : (React.createElement(React.Fragment, null, docusealToken && (React.createElement(DocusealBuilder, { autosave: false, requiredFields: [
                    { name: 'Signature', type: 'signature' },
                    { name: 'Date', type: 'date' },
                ], onlyDefinedFields: true, withAddPageButton: false, withDocumentsList: false, withFieldPlaceholder: false, withRecipientsButton: false, withSendButton: false, withUploadButton: false, onLoad: function (e) {
                    var _a;
                    if (((_a = e === null || e === void 0 ? void 0 : e.fields) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        setOnPreview(e.slug);
                    }
                    setLoading(false);
                }, onSave: function (e) { return setOnPreview(e.slug); }, withSignYourselfButton: false, token: docusealToken })))))))),
        React.createElement("div", { className: "h-screen w-screen z-10 fixed top-0 left-0 bg-black opacity-25", onClick: onClose })));
};
export default LimitPolicyPayment;
