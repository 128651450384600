import { format } from 'date-fns';
import React from 'react';
import usePolicyChangesStore from '../../../../stores/policyChangesStore';
import useWidgetStore, { CurrentTabEnum } from '../../../../stores/widgetStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, } from '@fortawesome/free-solid-svg-icons';
import * as changeCase from 'change-case';
var collectChangedKeys = function (changes, currentPath, result) {
    if (currentPath === void 0) { currentPath = []; }
    if (result === void 0) { result = []; }
    changes.forEach(function (change) {
        if (Array.isArray(change)) {
            if (Array.isArray(change[0])) {
                collectChangedKeys(change, currentPath, result);
            }
            else {
                var newCurrentPath = currentPath.concat(change[0]);
                collectChangedKeys(change.slice(1), newCurrentPath, result);
            }
        }
        else {
            var fullPath = currentPath.concat(change);
            result.push(fullPath);
        }
    });
    return result;
};
var getNestedProperty = function (obj, path) {
    return path.reduce(function (acc, key) { return (acc && acc[key] !== undefined ? acc[key] : undefined); }, obj);
};
var ChangesModal = function (_a) {
    var _b;
    var id = _a.id, changeTitle = _a.changeTitle, orignialData = _a.orignialData, prevData = _a.prevData, currentData = _a.currentData, changes = _a.changes;
    var setPolicyChanges = usePolicyChangesStore().setPolicyChanges;
    var setCurrentTab = useWidgetStore().setCurrentTab;
    return (React.createElement("div", { className: "w-[30rem] bg-white py-2 rounded-xl px-2 shadow-2xl overflow-auto" },
        React.createElement("table", { className: "w-full border-collapse" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: "border border-[#F0F2F9] p-4 w-6/12" },
                        React.createElement("button", { className: "w-full px-2 py-2 border-2 border-primary text-sm font-semibold text-primary rounded-xl", onClick: function (e) {
                                e.stopPropagation();
                                setPolicyChanges({
                                    changeTitle: changeTitle,
                                    originalQuoteData: orignialData,
                                    prevQuoteData: prevData,
                                    currentQuoteData: currentData
                                });
                                setCurrentTab(CurrentTabEnum.VIEW_POLICY_CHANGES);
                            } }, "Show all Changes")),
                    orignialData && (React.createElement("th", { className: "border border-[#F0F2F9] font-normal text-sm text-[#1F2751] p-4 w-full" }, "1st Quote / Policy")),
                    prevData && (React.createElement("th", { className: "border border-[#F0F2F9] font-normal text-sm text-[#1F2751] p-4 w-full" }, format(new Date(prevData === null || prevData === void 0 ? void 0 : prevData.createdAt), 'dd MMMM yyyy'))),
                    currentData && (React.createElement("th", { className: "border border-[#F0F2F9] font-semibold text-sm text-[#1F2751] p-4 w-full" }, format(new Date(currentData === null || currentData === void 0 ? void 0 : currentData.createdAt), 'dd MMMM yyyy'))))),
            React.createElement("tbody", null, (_b = changes === null || changes === void 0 ? void 0 : changes[0]) === null || _b === void 0 ? void 0 : _b.map(function (change) {
                var _a, _b, _c;
                var original = getNestedProperty((_a = orignialData === null || orignialData === void 0 ? void 0 : orignialData.items) === null || _a === void 0 ? void 0 : _a.requiredParams, change);
                var prev = getNestedProperty((_b = prevData === null || prevData === void 0 ? void 0 : prevData.items) === null || _b === void 0 ? void 0 : _b.requiredParams, change);
                var curr = getNestedProperty((_c = currentData === null || currentData === void 0 ? void 0 : currentData.items) === null || _c === void 0 ? void 0 : _c.requiredParams, change);
                return (React.createElement("tr", { className: "w-full" },
                    React.createElement("td", { className: "border border-[#F0F2F9] p-4 text-sm font-semibold text-[#1F2751] text-center w-max" }, change
                        .map(function (changeKey) {
                        return typeof changeKey === 'string'
                            ? changeCase.capitalCase(changeKey.replaceAll('_', ' '))
                            : changeKey;
                    })
                        .join(' • ')),
                    original && (React.createElement("td", { className: "border border-[#F0F2F9] p-4 text-sm font-semibold text-[#1F2751] w-full text-center" }, original)),
                    prev && (React.createElement("td", { className: "border border-[#F0F2F9] p-4 text-sm font-semibold text-[#1F2751] w-full text-center" }, prev)),
                    curr && (React.createElement("td", { className: "border border-[#F0F2F9] p-4 text-sm font-semibold text-primary w-full text-center" }, curr))));
            })))));
};
var Button = function (_a) {
    var handleOpenModal = _a.handleOpenModal, handleCloseModal = _a.handleCloseModal, isModalOpen = _a.isModalOpen;
    return (React.createElement("button", { className: "border-2 border-primary p-3 rounded-full w-min mx-6", title: "Click to see more", onClick: function () { return (isModalOpen ? handleCloseModal() : handleOpenModal()); } },
        React.createElement("div", { className: "bg-primary p-2 rounded-full" })));
};
var TimeStamp = function (_a) {
    var id = _a.id, changeTitle = _a.changeTitle, changeNo = _a.changeNo, createdAt = _a.createdAt, originalData = _a.originalData, prevData = _a.prevData, currentData = _a.currentData, changes = _a.changes, showModal = _a.showModal, setShowModal = _a.setShowModal, _b = _a.isFirst, isFirst = _b === void 0 ? false : _b, _c = _a.isLast, isLast = _c === void 0 ? false : _c, _d = _a.isSecondLast, isSecondLast = _d === void 0 ? false : _d, _e = _a.isReverse, isReverse = _e === void 0 ? false : _e, _f = _a.isCollapsed, isCollapsed = _f === void 0 ? false : _f;
    return (React.createElement("div", { className: "".concat(isLast ? 'w-full' : 'w-full', " relative"), onBlur: function (e) {
            if (!e.currentTarget.contains(e.relatedTarget)) {
                setShowModal(false);
            }
        }, tabIndex: -1 },
        React.createElement("div", { className: "w-full flex flex-col items-start" },
            React.createElement("div", { className: "flex items-center ".concat(!isLast && 'w-full') },
                React.createElement("div", { className: "flex items-center flex-col ".concat(!changeNo && 'mb-5') },
                    React.createElement("span", { className: "text-gray-800 text-sm font-medium ml-2 w-max" }, format(new Date(createdAt), 'dd MMMM yyyy')),
                    React.createElement("span", { className: "text-gray-800 font-bold text-sm mb-2 ml-2 w-max max-w-[7.5rem] truncate", title: changeTitle }, changeTitle),
                    React.createElement(Button, { handleOpenModal: function () { return setShowModal(id); }, handleCloseModal: function () { return setShowModal(false); }, isModalOpen: showModal }),
                    changeNo && (React.createElement("div", { className: "flex items-center gap-x-2" },
                        React.createElement("span", { className: "text-xs font-semibold my-1" },
                            "Change ",
                            changeNo)))),
                !isLast && (React.createElement("div", { className: "flex items-center ".concat(isReverse ? 'flex-row-reverse' : 'flex-row', " w-full mt-6") },
                    React.createElement("div", { className: "h-[2px] w-full bg-gray-300" }),
                    isReverse ? (React.createElement(FontAwesomeIcon, { icon: faChevronLeft, className: "text-gray-300 -mr-2" })) : (React.createElement(FontAwesomeIcon, { icon: faChevronRight, className: "text-gray-300 -ml-2" })))))),
        (changes === null || changes === void 0 ? void 0 : changes.length) > 0 && showModal && (React.createElement("div", { className: "absolute top-24 ".concat(isLast && 'top-28', " ").concat((isLast || isSecondLast) && !isCollapsed
                ? 'right-0'
                : isFirst
                    ? 'left-0'
                    : 'left-[-40%]', " z-50"), onMouseDown: function (e) { return e.stopPropagation(); } },
            React.createElement(ChangesModal, { id: id, changeTitle: changeTitle, orignialData: originalData, prevData: prevData, currentData: currentData, changes: changes })))));
};
export default TimeStamp;
