import React, { useState } from 'react';
import useConfigStore from '../../stores/configStore';
import useWidgetStore from '../../stores/widgetStore';

const Modal = ({ children }) => {
  const { modalOpen } = useWidgetStore();
  const {
    style: { width, height },
  } = useConfigStore();

  return (
    <div
      tabIndex={-1}
      className={
        modalOpen &&
        `overflow-y-hidden overflow-x-hidden fixed top-0 left-0 h-modal sm:pt-[2.5vh] z-50 inset-0 h-modal bg-black bg-opacity-90	`
      }
    >
      <div
        className={`${modalOpen ? "relative w-screen h-screen sm:w-[95vw] sm:h-[95vh] mx-auto overflow-y-auto" : `w-[${width}] h-[${height}]` }`
        }
        style={{
          width: !modalOpen && width,
          height: !modalOpen && height,
        }}
      >
        <div
          className={
            modalOpen &&
            `relative overflow-y-auto h-full bg-backgroundColor sm:rounded-lg shadow`
          }
        >
          <span>{children}</span>
        </div>
      </div>
    </div>
  );
};

export default Modal;
