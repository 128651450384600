import React, { useState } from 'react';
import TimeStamp from './TimeStamp';
import _ from 'lodash';
import 'rc-tooltip/assets/bootstrap_white.css';
import QuoteLoading from '../../../Tabs/Policy/QuoteLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, } from '@fortawesome/free-solid-svg-icons';
var Timeline = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var customerData = _a.customerData;
    var _o = useState(false), showAll = _o[0], setShowAll = _o[1];
    var _p = useState(false), showModal = _p[0], setShowModal = _p[1];
    var toggleShowAll = function () {
        setShowAll(!showAll);
    };
    var renderTimeStamps = function (data, reverse, isFirst, isCollapsed) {
        if (reverse === void 0) { reverse = false; }
        if (isFirst === void 0) { isFirst = false; }
        if (isCollapsed === void 0) { isCollapsed = false; }
        var finalArr = reverse ? _.reverse(data) : data;
        var items = finalArr.map(function (timestamp, index) { return (React.createElement("div", { className: "".concat(!isFirst && 'flex flex-col', " ").concat(index == finalArr.length - 1 ? 'w-max items-center' : 'w-full') },
            !isFirst &&
                ((index == finalArr.length - 1 && reverse) ||
                    (index == 0 && !reverse)) && (React.createElement("div", { className: "flex flex-col items-start ".concat(index == 0 &&
                    !reverse &&
                    index !== finalArr.length - 1 &&
                    'ml-11', " mb-1") },
                React.createElement("div", { className: "flex flex-col items-center" },
                    React.createElement("div", { className: "h-16 w-[2px] bg-gray-300" }),
                    React.createElement(FontAwesomeIcon, { icon: faChevronDown, className: "text-gray-300 -mt-3" })))),
            React.createElement(TimeStamp, { key: index, id: timestamp.id, changeNo: timestamp === null || timestamp === void 0 ? void 0 : timestamp.changeNo, changeTitle: timestamp === null || timestamp === void 0 ? void 0 : timestamp.changeTitle, createdAt: timestamp === null || timestamp === void 0 ? void 0 : timestamp.createdAt, isSecondLast: index === data.length - 2, isLast: index === data.length - 1, isFirst: isFirst || index == 0, originalData: timestamp === null || timestamp === void 0 ? void 0 : timestamp.originalPolicyData, prevData: timestamp === null || timestamp === void 0 ? void 0 : timestamp.prevQuoteData, currentData: {
                    items: timestamp === null || timestamp === void 0 ? void 0 : timestamp.items,
                    createdAt: timestamp === null || timestamp === void 0 ? void 0 : timestamp.createdAt
                }, changes: timestamp === null || timestamp === void 0 ? void 0 : timestamp.changes, showModal: showModal == timestamp.id, setShowModal: setShowModal, isReverse: reverse, isCollapsed: isCollapsed }))); });
        return items;
    };
    if (!customerData) {
        return React.createElement(QuoteLoading, null);
    }
    return (React.createElement("div", null, ((_b = customerData === null || customerData === void 0 ? void 0 : customerData.data) === null || _b === void 0 ? void 0 : _b.data.length) > 6 ? (showAll ? (React.createElement("div", { className: "flex flex-col items-start w-full" },
        React.createElement("div", { className: "flex flex-col w-full gap-y-2" }, _.chunk((_c = customerData === null || customerData === void 0 ? void 0 : customerData.data) === null || _c === void 0 ? void 0 : _c.data, 6).map(function (chunk, index) { return (React.createElement("div", { key: index, className: "flex flex-row items-end ".concat(index % 2 !== 0 ? 'justify-end' : 'justify-start', " w-full gap-y-2") }, renderTimeStamps(chunk, index % 2 !== 0, index == 0))); })),
        React.createElement("div", { className: "flex items-center justify-center w-full my-3" },
            React.createElement("button", { onClick: toggleShowAll, className: "border-2 border-primary rounded-lg text-primary font-semibold px-3 py-1" }, "Show Less")))) : (React.createElement("div", { className: "flex flex-col items-start w-full" },
        React.createElement("div", { className: "flex items-start w-full gap-y-2" },
            React.createElement("div", { className: "flex flex-row items-end justify-start w-full" }, renderTimeStamps((_e = (_d = customerData === null || customerData === void 0 ? void 0 : customerData.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.slice(0, 2), false, true, true)),
            React.createElement("div", { className: "flex items-center w-full mt-12" },
                React.createElement("div", { className: "flex items-center flex-row w-full" },
                    React.createElement("div", { className: "h-[2px] w-full bg-gray-300" }),
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight, className: "text-gray-300 -ml-2" })),
                React.createElement("button", { onClick: toggleShowAll, className: "border-2 border-primary rounded-lg text-primary font-semibold px-3 py-1 my-1 mx-3 w-full" }, "Show More"),
                React.createElement("div", { className: "flex items-center flex-row w-full" },
                    React.createElement("div", { className: "h-[2px] w-full bg-gray-300" }),
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight, className: "text-gray-300 -ml-2" }))),
            React.createElement("div", { className: "flex flex-row items-end justify-start w-full" }, renderTimeStamps((_g = (_f = customerData === null || customerData === void 0 ? void 0 : customerData.data) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.slice(((_j = (_h = customerData === null || customerData === void 0 ? void 0 : customerData.data) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.length) - 2, (_l = (_k = customerData === null || customerData === void 0 ? void 0 : customerData.data) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.length), false, true)))))) : (React.createElement("div", { className: "flex flex-col items-start w-full" },
        React.createElement("div", { className: "flex flex-col w-full gap-y-2" },
            React.createElement("div", { className: "flex flex-row items-end justify-start w-full" }, renderTimeStamps((_m = customerData === null || customerData === void 0 ? void 0 : customerData.data) === null || _m === void 0 ? void 0 : _m.data, false, true)))))));
};
export default Timeline;
