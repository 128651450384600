var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useState } from 'react';
import ClaimList from './pages/ClaimList';
import ViewClaim from './pages/ViewClaim';
import { useQuery } from 'react-query';
import api from '../../../api';
import CardLoader from '../../CardLoader';
import ClaimEditor from '../../common/ClaimEditor';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { getClaimTotal } from '../../common/getClaimTotal';
import { useInsurance } from '../../../context/Insurance';
var Claims = function (_a) {
    var primaryColor = _a.primaryColor, customerID = _a.customerID, setOpenModal = _a.setOpenModal, propsClaims = _a.propsClaims, initiailzedPlatformClaims = _a.initiailzedPlatformClaims, setInitiailzedPlatformClaims = _a.setInitiailzedPlatformClaims;
    var _b = useState(0), page = _b[0], setPage = _b[1];
    var _c = useState(null), activeClaim = _c[0], setActiveClaim = _c[1];
    var _d = useState(false), editMode = _d[0], setEditMode = _d[1];
    var latestPolicy = useInsurance().latestPolicy;
    var queryClient = useQueryClient();
    var initCreateOrUpdateClaim = function () { return __awaiter(void 0, void 0, void 0, function () {
        var serverClaims;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    serverClaims = (_a = allClaimsData === null || allClaimsData === void 0 ? void 0 : allClaimsData.data) === null || _a === void 0 ? void 0 : _a.data;
                    if (!serverClaims)
                        return [2 /*return*/];
                    if (!((propsClaims === null || propsClaims === void 0 ? void 0 : propsClaims.length) > 0))
                        return [2 /*return*/];
                    if (initiailzedPlatformClaims)
                        return [2 /*return*/];
                    setInitiailzedPlatformClaims(true);
                    return [4 /*yield*/, (propsClaims === null || propsClaims === void 0 ? void 0 : propsClaims.map(function (pc) {
                            return new Promise(function (resolve, reject) {
                                console.log('Checking propsClaim: ', pc, serverClaims);
                                var matchingClaimIdx = serverClaims &&
                                    serverClaims.findIndex(function (cl) { return cl.claimId === Number(pc.claimId); });
                                if (matchingClaimIdx > -1) {
                                    console.log('Found matching server claim: ', serverClaims[matchingClaimIdx]);
                                    resolve(updateClaim.mutateAsync(__assign(__assign({}, pc), { claimId: pc === null || pc === void 0 ? void 0 : pc.claimId })));
                                }
                                else {
                                    console.log('Did not find server claim, creating new.');
                                    resolve(generateNewPlatformClaim(pc));
                                }
                            });
                        }))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    // queries
    var _e = useQuery('claim', api.claim.getAll), allClaimsData = _e.data, isLoading = _e.isLoading;
    // mutations
    var createClaim = useMutation(api.claim.create, {
        onSuccess: function () {
            queryClient.invalidateQueries('claim');
            setOpenModal(false);
            setPage(0);
        }
    });
    var updateClaim = useMutation(api.claim.update, {
        onSuccess: function () {
            queryClient.invalidateQueries('claim');
            setOpenModal(false);
            setPage(0);
        }
    });
    //
    var generateNewPlatformClaim = function (pc) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (latestPolicy === null || latestPolicy === void 0 ? void 0 : latestPolicy.policyId) {
                return [2 /*return*/, createClaim.mutateAsync({
                        customerId: customerID,
                        title: '',
                        impact: '',
                        policyId: latestPolicy.policyId,
                        item: pc.item,
                        totalInvoice: getClaimTotal(pc)
                    })];
            }
            else {
                console.log('Must have a policy before generating claim');
                throw {
                    message: 'Must have a policy before generating claim'
                };
            }
            return [2 /*return*/];
        });
    }); };
    // effects
    useEffect(function () {
        initCreateOrUpdateClaim();
        console.log('Propsclaims: ', propsClaims);
    }, [propsClaims, allClaimsData]);
    useEffect(function () {
        setOpenModal(editMode);
    }, [editMode]);
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (activeClaim && ((_b = (_a = allClaimsData === null || allClaimsData === void 0 ? void 0 : allClaimsData.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            var idx = (_d = (_c = allClaimsData === null || allClaimsData === void 0 ? void 0 : allClaimsData.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.findIndex(function (c) { return c.claimId === (activeClaim === null || activeClaim === void 0 ? void 0 : activeClaim.claimId); });
            if (idx !== -1)
                setActiveClaim((_e = allClaimsData === null || allClaimsData === void 0 ? void 0 : allClaimsData.data) === null || _e === void 0 ? void 0 : _e.data[idx]);
        }
    }, [allClaimsData]);
    //
    if (isLoading || !customerID)
        return React.createElement(CardLoader, { color: primaryColor, text: "Loading claims..." });
    if (createClaim === null || createClaim === void 0 ? void 0 : createClaim.isLoading)
        return React.createElement(CardLoader, { color: primaryColor, text: "Filing claim..." });
    if (updateClaim === null || updateClaim === void 0 ? void 0 : updateClaim.isLoading)
        return React.createElement(CardLoader, { color: primaryColor, text: "Updating claim..." });
    if (!(latestPolicy === null || latestPolicy === void 0 ? void 0 : latestPolicy.policyId))
        return (React.createElement("div", { className: "text-left px-8 mx-0 my-auto h-full w-full overflow-y-scroll m-3 flex flex-row justify-center items-center" },
            React.createElement("h1", { className: "text-lg text-red-500 font-bold self-center" }, "Please select a policy before viewing claims.")));
    if (activeClaim !== null) {
        return editMode ? (
        // for updating existing claim
        React.createElement(ClaimEditor, { goBack: function () { return setEditMode(false); }, primaryColor: primaryColor, setOpenModal: setOpenModal, customerID: customerID, setEditMode: setEditMode, activeClaim: activeClaim, handleSubmit: function (v) {
                return updateClaim.mutate(__assign(__assign({}, v), { claimId: activeClaim === null || activeClaim === void 0 ? void 0 : activeClaim.claimId }));
            } })) : (React.createElement(ViewClaim, { activeClaim: activeClaim, setActiveClaim: setActiveClaim, setEditMode: setEditMode, primaryColor: primaryColor }));
    }
    return [
        React.createElement(ClaimList, { primaryColor: primaryColor, setPage: setPage, setActiveClaim: setActiveClaim, allClaimsData: allClaimsData, setOpenModal: setOpenModal }),
        // for filing new claim
        React.createElement(ClaimEditor, { goBack: function () { return setPage(0); }, primaryColor: primaryColor, setOpenModal: setOpenModal, customerID: customerID, handleSubmit: function (v) { return createClaim.mutate(v); } }),
    ][page];
};
export default Claims;
