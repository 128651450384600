function getStyles(themeName) {
    var styles = {
        widgetBorderColor: '#E8E8E8',
        tabNonActive: '#ecf0f1',
        backgroundColor: "white",
        textColor: "black",
        inputColor: "white",
        inputBorder: "primary",
        loadingColor: "#d1d5db"
    };
    // switch (themeName) {
    //   case 'night':
    //     styles = {
    //       widgetBorderColor: 'rgb(32 34 38)',
    //       tabNonActive: '#16181a',
    //       backgroundColor: "#111315",
    //       textColor: "white",
    //       inputColor: "#1C1F22",
    //       inputBorder: "",
    //       loadingColor: "#4b5563"
    //     };
    //     break;
    //   default:
    //     styles = {
    //       widgetBorderColor: '#E8E8E8',
    //       tabNonActive: '#ecf0f1',
    //       backgroundColor: "white",
    //       textColor: "black",
    //       inputColor: "white",
    //       inputBorder: "primary",
    //       loadingColor: "#d1d5db"
    //     };
    // }
    return styles;
}
export default getStyles;
