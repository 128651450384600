var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
var useConfigStore = create(function (set) { return ({
    style: {
        primaryColor: '#800080',
        secondaryColor: '#ffffff',
        width: '100%',
        height: '455px',
        logo: undefined,
        theme: 'default'
    },
    keys: {
        token: null,
        platformId: null,
        widgetType: 'kayna'
    },
    setConfig: function (config) {
        set(function (state) {
            var _a, _b, _c, _d;
            var updatedStyles = __assign(__assign(__assign({}, state.style), config.style), { theme: 'default' });
            var newState = {
                style: updatedStyles,
                keys: __assign(__assign(__assign({}, state === null || state === void 0 ? void 0 : state.keys), config === null || config === void 0 ? void 0 : config.keys), { widgetType: ((_a = config === null || config === void 0 ? void 0 : config.keys) === null || _a === void 0 ? void 0 : _a.widgetType)
                        ? (_b = config === null || config === void 0 ? void 0 : config.keys) === null || _b === void 0 ? void 0 : _b.widgetType
                        : ((_c = state === null || state === void 0 ? void 0 : state.keys) === null || _c === void 0 ? void 0 : _c.widgetType)
                            ? (_d = state === null || state === void 0 ? void 0 : state.keys) === null || _d === void 0 ? void 0 : _d.widgetType
                            : 'kayna' })
            };
            return newState;
        });
    }
}); });
export default useConfigStore;
